<template>
    <v-navigation-drawer
        app
        :class="collapsed ? 'show' : 'hide'"
        clipped
        stateless
        hide-overlay
        floating
        :temporary="false"
        :disable-resize-watcher="true"
        :value="!collapsed"
        class="drawer pt-4"
        color="#201F1F"
        width="360px"
        style="color: #ffffff !important">
        <template #prepend>
            <div class="d-flex flex-column pl-6 pr-2">
                <div
                    class="d-flex flex-row align-items-center justify-space-between pr-1 py-2 mt-3"
                >
                    <div class="font-weight-medium d-flex">
                        <div
                            @click="reloadPage"
                            style="font-size: 27px; cursor: pointer"
                        >
                            FreightGPT
                        </div>
                        <v-chip
                            color="#1DD3C0"
                            small
                            class="font-weight-medium text-subtitle-1 betaChip px-2 mt-5"
                            style="
                                position: absolute;
                                top: 3.8rem;
                                left: 1.5rem;
                            "
                            >Beta</v-chip
                        >
                    </div>

                    <v-spacer />

                    <!-- Inner btn -->
                    <svg
                        @click="toggle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        class="toggleBtn"
                        :class="collapsed ? 'hide' : 'show'"
                        style="
                            position: static;
                            align-self: center;
                            margin-right: 0.8rem;
                            justify-self: end;
                        "
                    >
                        <g
                            fill="none"
                            stroke="rgb(220,220,220)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        >
                            <path
                                d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2Z"
                            />
                            <path d="M7.25 10L5.5 12l1.75 2m2.25 7V3" />
                        </g>
                    </svg>

                    <!-- Outer btn -->
                    <svg
                        @click="toggle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        class="toggleBtn"
                        :class="collapsed ? 'show' : 'hide'"
                        style="
                            visibility: visible !important;
                            top: calc(3% + 1rem);
                            position: absolute;
                            right: -4.8rem
                        "
                    >
                        <g
                            fill="none"
                            stroke="rgb(220,220,220)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        >
                            <path
                                d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2Zm-9.5 0V3"
                            />
                            <path d="m5.5 10l1.75 2l-1.75 2" />
                        </g>
                    </svg>
                </div>
            </div>
        </template>

        <div
            class="d-flex flex-column align-center justify-start pt-6 px-2"
        >
            <v-btn
                class="text-none font-weight-light py-4 pl-4 mt-5"
                outlined
                min-width="92%"
                max-width="92%"
                height="fit-content"
                :ripple="false"
                style="
                    justify-content: start;
                    border-width: 0.4px !important;
                    border-color: #3181b6;
                    font-size: 0.9rem;
                "
                :style="[
                    newSessionBlocked
                        ? { color: '#999999 !important' }
                        : { color: '#ffffff !important' }
                ]"
                @click="resetRFPTool"
            >
                <v-icon
                    style="margin-right: 8px; height: 28px; width: 28px"
                >mdi-file-document-plus-outline</v-icon>
                New RFP
            </v-btn>
            <v-progress-linear
                v-if="isLoading"
                indeterminate
                style="width: 100%; position: absolute; top: 12rem"
            />
        </div>
        <div style="position: relative; top: 40%; left: 20%; color: #a1a1a1">
            Saved RFPs coming soon...
        </div>

        <template v-slot:append>
            <SideBarFooter :user_info="user_info" />
        </template>
    </v-navigation-drawer>
</template>

<script>
import SideBarFooter from '../SideBarFooter.vue';

export default {
    name: 'RFPSideBar',
    components: {
        SideBarFooter
    },
    props: [
        'user_info',
    ],
    methods: {
        toggle() {
            this.collapsed = !this.collapsed;
            this.$emit('updateCollapseValue', this.collapsed);
        },
        reloadPage() {
            location.reload();
        },

        resetRFPTool() {
            this.$emit('resetRFPTool');
        },
    },
    data() {
        return {
            isLoading: false,
            collapsed: false,
            newSessionBlocked: false,
        };
    },
}
</script>

<style scoped>
.drawer {
    overflow: visible;
}
.drawer.show {
    transform: 0;
    transition: all 0.3s ease-out !important;
    -webkit-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
}
.drawer.hide {
    transform: translateX(-100%);
    transition: all 0.3s ease-out !important;
    -webkit-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
}
.toggleBtn.show {
    opacity: 1;
    z-index: 50;
    width: min-content;
    transition: opacity 0.2s 0.3s ease-in;
    -webkit-transition: opacity 0.2s 0.3s ease-in;
    -o-transition: opacity 0.2s 0.3s ease-in;
    -moz-transition: opacity 0.2s 0.3s ease-in;
    cursor: pointer;
}
.toggleBtn.hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
    -webkit-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
}
.betaChip {
    border-radius: 6px;
    color: white;
}
</style>
