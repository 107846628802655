<template>
    <div style="display: flex; min-height: 400px; width: 100%; max-height: 100%; flex-direction: column; position: relative; flex-grow: 2">
        <header style="font-size: 20px; font-weight: 700; margin-top: 20px">Pricing Options</header>
        <section style="padding: 0px; flex: 1; overflow-y: auto">
            <!-- <div class="options-header">Bulk Pricing Type</div>
            <div class="options-card">
                <div style="display: flex">
                    <v-btn
                        class="pricing-type-btn"
                        :style="{ backgroundColor: pricingOptions.pricingType === 'spot' ? '#32C3FD' : '',color: pricingOptions.pricingType === 'spot'? 'white':'#9B9B9B'}"
                        @click="pricingOptions.pricingType ='spot'"
                        >Spot</v-btn
                    ><v-btn
                        class="pricing-type-btn"
                        :style="{ backgroundColor: pricingOptions.pricingType === 'contract' ? '#32C3FD' : '',color: pricingOptions.pricingType === 'contract'? 'white':'#9B9B9B' }"
                        @click="pricingOptions.pricingType = 'contract'"
                        >Contract
                    </v-btn>
                </div>
                <div :class="pricingOptions.pricingType === 'spot' ? 'disabled' : ''" style="margin-top: 20px; margin-bottom: 12px">
                    <div style="font-weight: 400; padding: 10px 0px; font-size: 18px">
                        Date Range
                    </div>
                    <div style="display: flex; font-weight: 300; font-size: 18px">
                        <div class="date-styles" style="margin-right: 10%">
                            <label for="startDate">Start Date</label>
                            <v-container style="padding: 0px 12px 0px 0px">
                                <v-menu
                                    ref="startMenu"
                                    v-model="startMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :nudge-bottom="40"
                                    transition="scale-transition"
                                    offset-y
                                    :disabled="pricingOptions.pricingType === 'spot'"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" :disabled="pricingOptions.pricingType === 'spot'">
                                            <v-text-field
                                                v-model="pricingOptions.startDate"
                                                readonly
                                                outlined
                                                class="cursor-default"
                                                :error-messages="startDateErrors"
                                                :disabled="pricingOptions.pricingType === 'spot'"
                                            >
                                                <template #append>
                                                    <v-icon style="cursor: default !important">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </template>
                                    <v-date-picker
                                        v-model="pricingOptions.startDate"
                                        :max="maxStartDate"
                                        class="cursor-default"
                                        :disabled="pricingOptions.pricingType === 'spot'"
                                        @input="onStartDateChange"
                                    ></v-date-picker>
                                </v-menu>
                            </v-container>
                        </div>
                        <div class="date-styles">
                            <label for="endDate">End Date</label>
                            <v-container style="padding: 0px 12px 0px 0px">
                                <v-menu
                                    ref="endMenu"
                                    v-model="endMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    :disabled="pricingOptions.pricingType === 'spot'"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <div v-bind="attrs" v-on="on" :disabled="pricingOptions.pricingType === 'spot'">
                                            <v-text-field
                                                v-model="pricingOptions.endDate"
                                                readonly
                                                outlined
                                                class="cursor-default"
                                                :error-messages="endDateErrors"
                                                :disabled="pricingOptions.pricingType === 'spot'"
                                            >
                                                <template #append>
                                                    <v-icon style="cursor: default !important">mdi-calendar</v-icon>
                                                </template>
                                            </v-text-field>
                                        </div>
                                    </template>
                                    <v-date-picker
                                        v-model="pricingOptions.endDate"
                                        :min="minEndDate"
                                        class="cursor-default"
                                        :disabled="pricingOptions.pricingType === 'spot'"
                                        @input="onEndDateChange"
                                    ></v-date-picker>
                                </v-menu>
                            </v-container>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="options-header">Markup</div>
            <div class="options-card">
                <div style="display: flex; font-weight: 300; font-size: 18px; justify-content: space-between">
                    <div style="margin-right: 48px">
                        <label style="margin-top: 6px">Target Markup</label>
                        <v-text-field
                            class="option-text-field"
                            outlined
                            hide-details
                            style="width: 100px; border-radius: 6px"
                            @input="validateMarkup($event)"
                            v-model="pricingOptions.markup.targetMarkupPercentage"
                        >
                            <template #append>
                                <span>%</span>
                            </template>
                        </v-text-field>
                    </div>
                    <span style="display: flex; width: 280px">
                        <div style="margin-right: 12px; width: 50%">
                            <label
                                style="margin-top: 6px"
                                :style="!applyMarkup ? 'color: rgba(255, 255, 255, 0.4)' : ''"
                                >Min</label
                            >
                            <v-text-field
                                :disabled="!applyMarkup"
                                :value="pricingOptions.markup.marginBound[0]"
                                class="option-text-field"
                                outlined
                                hide-details
                                @change="$set(pricingOptions.markup.marginBound, 0, $event)"
                            >
                                <template #prepend-inner>
                                    <div>$</div>
                                </template>
                            </v-text-field>
                        </div>
                        <div style="width: 50%">
                            <label
                                style="margin-top: 6px"
                                :style="!applyMarkup ? 'color: rgba(255, 255, 255, 0.4)' : ''"
                                >Max
                            </label>
                            <v-text-field
                                :disabled="!applyMarkup"
                                :value="pricingOptions.markup.marginBound[1]"
                                class="option-text-field"
                                outlined
                                hide-details
                                @change="$set(pricingOptions.markup.marginBound, 1, $event)"
                            >
                                <template #prepend-inner>
                                    <div>$</div>
                                </template>
                            </v-text-field>
                        </div>
                    </span>
                    <div
                        style="
                            padding: 20px;
                            padding-top: 32px;
                            width: 40%;
                            display: flex;
                            flex-grow: 2;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        "
                    >
                        <v-range-slider
                            :disabled="!applyMarkup"
                            v-model="pricingOptions.markup.marginBound"
                            thumb-label="always"
                            min="0"
                            max="1500"
                            step="25"
                            hide-details
                            class="custom-slider"
                        >
                            <template #thumb-label="{ value }">
                                <span style="font-weight: 360; font-size: 16px">${{ value }}</span>
                            </template>
                        </v-range-slider>
                    </div>
                </div>
            </div>
        </section>

        <div style="display: flex; margin-top: 10px; justify-content: flex-end">
            <!-- <span
              v-if="pricingOptions.pricingType == 'contract' && optionsInvalid"
              style="
                color: firebrick;
                margin-right: 6px;
                height: fit-content;
                align-self: center;
              "
              >Contract date range not specified</span
            > -->
            <div>
                <v-btn
                    style="border: 1px solid #ffc456;"
                    class="nav-btn"
                    @click="$emit('changeCurrentState','equipment');"
                >
                    <v-icon size="22" style="margin-right: 8px">
                        mdi-skip-previous-outline
                    </v-icon>
                    Back
                </v-btn>
                <v-btn
                    style="border: 1px solid #1dd3c0;"
                    class="nav-btn"
                    @click="sendPricingRequest"
                >
                    Price Lanes
                    <v-icon size="22" style="margin-left: 8px">
                        mdi-skip-next-outline
                    </v-icon>
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import { set } from 'vue';
import { v4 as uuidv4 } from 'uuid';
import { isDevEnv, APIEndpoints } from '../../resources';

export default {
    name: 'PricingStep',
    components: {},
    props: {
        websocket: {
            type: Object,
            required: true,
        },
        mapped_file_path: {
            type: String,
            required: true,
        },
        saved_pricing_options : {
            type: Object,
            default: null
        },
        rfp_session_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            startMenu: false,
            endMenu: false,
            pricingOptions: {
                pricingType: 'spot',
                dateRange: [],
                startDate:null,
                endDate:null,
                markup: {
                    targetMarkupPercentage: '',
                    marginBound: [0, 10000],
                },
                fuel: {
                    fuelSurchargeCents: 0,
                },
            },
            pricingEndpoint: isDevEnv() ? APIEndpoints.dev.rfp_pricing_endpoint : APIEndpoints.prod.rfp_pricing_endpoint,
            applyMarkup: false,
            optionsInvalid: false,
            startDateErrors: [],
            endDateErrors: [],
            delayValidation: true,
            componentUid: uuidv4(),
        };
    },
    computed: {
        maxStartDate() {
            if (this.pricingOptions.endDate) {
                return this.pricingOptions.endDate;
            } else {
                return undefined;
            }
        },
        minEndDate() {
            return this.pricingOptions.startDate || '1900-01-01';
        },
        leftThumbStyle() {
            const percentage = ((this.pricingOptions.markup.marginBound[0] - 0) / (1500 - 0)) * 100;
            return { left: `calc(${percentage}%)` }; // Adjust '15px' based on the thumb size
        },
        rightThumbStyle() {
            const percentage = ((this.pricingOptions.markup.marginBound[1] - 0) / (1500 - 0)) * 100;
            return { left: `calc(${percentage}% + 15px)` }; // Adjust '15px' based on the thumb size
        },
        contractSwitch() {
            return this.pricingOptions.pricingType === 'contract';
        },
        dateRangeText() {
            if (this.pricingOptions.dateRange.length < 1) return '';
            function formatDate(date) {
                const [year, month, day] = date.split('-');
                return `${month}/${day}/${year}`;
            }
            const date1 = formatDate(this.pricingOptions.dateRange[0])
            if (this.pricingOptions.dateRange.length < 2) return date1 + ' - ';
            const date2 = formatDate(this.pricingOptions.dateRange[1]) || '';
            return `${date1} - ${date2}`;
        },
        startDate() {
            return this.pricingOptions.dateRange[0] || null;
        },
        endDate() {
            return this.pricingOptions.dateRange[1] || null;
        },
    },
    methods: {
        onStartDateChange(date) {
            this.pricingOptions.startDate = date;
            this.startMenu = false;
            // Update end date min date to prevent it from being less than the start date
            this.pricingOptions.endDate = this.pricingOptions.endDate < this.pricingOptions.startDate
                ? this.pricingOptions.startDate
                : this.pricingOptions.endDate;
        },
        handlePricingResponse(response) {
            let responseData = JSON.parse(response.data);
            if ('close' in responseData) {
                this.$emit('loading', false);
                return;
            } else if ('fatalError' in responseData && responseData.fatalError) {
                this.$emit('loading', false);
                this.$emit('displayError', responseData.fatalError);
                this.$emit('changeCurrentState', 'upload');
            } else if ('pricedFilename' in responseData) {
                this.$emit('loading', false);
                this.$emit('pricingResponse', responseData);
                this.$emit('proceedToResult');
            }
        },
        packagePricingOptions() {
            let markup = {
                ...this.pricingOptions.markup.targetMarkupPercentage && { targetMarkupPercentage: this.pricingOptions.markup.targetMarkupPercentage },
                ...(this.pricingOptions.markup.marginBound[0] !== 0 && this.pricingOptions.markup.marginBound[1] !== 10000) && { marginBound: this.pricingOptions.markup.marginBound },
            };
            let options = {
                pricingType: this.pricingOptions.pricingType,
                ...this.startDate && { startDate: this.startDate },
                ...this.endDate && { endDate: this.endDate },
                markup: markup,
            };
            return options;
        },
        validateOptions() {
            if (this.pricingOptions.pricingType === 'contract' && (!this.pricingOptions.startDate || !this.pricingOptions.endDate)) {
                if (!this.pricingOptions.startDate) {
                    this.startDateErrors = ['Start date is required'];
                }
                if (!this.pricingOptions.endDate) {
                    this.endDateErrors = ['End date is required'];
                }
                return false;
            }
            return true;
        },
        sendPricingRequest() {
            if (this.validateOptions()) {
                this.$emit('loading', 'Calculating Prices');
                const messageObject = {
                    action: this.pricingEndpoint,
                    user_id: this.user_id,
                    rfp_session_id: this.rfp_session_id,
                    mappedFilename: this.mapped_file_path,
                    ...this.packagePricingOptions(),
                };
                this.websocket.sendMessage(messageObject, this.handlePricingResponse);
            }
        },
        onEndDateChange(date) {
            this.pricingOptions.endDate = date;
            this.endMenu = false;
            // Update start date max date to prevent it from being more than the end date
            this.pricingOptions.startDate = this.pricingOptions.startDate > this.pricingOptions.endDate
                ? this.pricingOptions.endDate
                : this.pricingOptions.startDate;
        },

        validateMarkup(value) {
            if (value === '') return;
            let cleanedVal = value.replace(/[^0-9.]/g, '');
            set(this.pricingOptions.markup, "targetMarkupPercentage", parseFloat(cleanedVal));
        },
        sortDateRange() {
            // Compare the ISO 8601 date strings in the date range and ensure that the chronological first date is always before the second
            if (this.pricingOptions.dateRange.length === 2) {
                let year1 = parseInt(this.pricingOptions.dateRange[0].split('-')[0]);
                let month1 = parseInt(this.pricingOptions.dateRange[0].split('-')[1]);
                let date1 = parseInt(this.pricingOptions.dateRange[0].split('-')[2]);
                let year2 = parseInt(this.pricingOptions.dateRange[1].split('-')[0]);
                let month2 = parseInt(this.pricingOptions.dateRange[1].split('-')[1]);
                let date2 = parseInt(this.pricingOptions.dateRange[1].split('-')[2]);
                if (year1 === year2) {
                    if (month1 === month2) {
                        if (date1 < date2) return;
                    } else if (month1 < month2) return;
                } else if (year1 < year2) return;
                let temp = this.pricingOptions.dateRange[0];
                this.pricingOptions.dateRange[0] = this.pricingOptions.dateRange[1];
                this.pricingOptions.dateRange[1] = temp;
            }
        },
        getOptionPreview(option) {
            switch (option) {
                case 'type':
                    if (this.pricingOptions.pricingType === 'contract') {
                        let dateSuffix = ''
                        if (this.dateRangeText){
                            dateSuffix = ': ' + this.dateRangeText;
                        }
                        return 'Contract' + dateSuffix;
                    } else {
                        return 'Spot';
                    }
                case 'markup': {
                    let range = null, target = null;
                    if (this.pricingOptions.markup.targetMarkupPercentage !== '') {
                        target = "Target: " + this.pricingOptions.markup.targetMarkupPercentage + "%"
                        if (Math.min(this.pricingOptions.markup.marginBound) != 0 || Math.max(this.pricingOptions.markup.marginBound) !== 10000) {
                            range = "Range: $" + this.pricingOptions.markup.marginBound.join(' - $');
                        }
                    }
                    if (range === null && target === null) {
                        return 'Not applied';
                    } else {
                        return [target, range].filter(Boolean).join(', ');
                    }
                }
                case 'fuel':
                    if (this.pricingOptions.fuel.fuelSurchargeCents == 0) {
                        return 'Not applied';
                    }
                    return this.pricingOptions.fuel.fuelSurchargeCents + "¢/mi";
            }
        },
    },
    mounted() {
        if (this.saved_pricing_options) {
            this.pricingOptions = this.saved_pricing_options;
        }
    },
    beforeDestroy() {
        this.$emit('updateSavedOptions', this.pricingOptions);
    },
    watch: {
        pricingOptions: {
            handler(val) {
                const targetMarkup = val.markup.targetMarkupPercentage;
                this.applyMarkup = (targetMarkup !== '');

                if (val.pricingType === 'contract' && !this.pricingOptions.startDate && !this.pricingOptions.endDate) {
                    if (!this.delayValidation) {
                        this.optionsInvalid = true;
                    } else {
                        this.delayValidation = false;
                    }
                }

            },
            deep: true
        },
    },
};
</script>

<style scoped>
.cursor-default ::v-deep div {
    cursor: default !important;
}
::v-deep .cursor-default input {
    cursor: default;
}
.cursor-default ::v-deep .v-input__append-inner {
    cursor: default;
}
::v-deep .v-input__append-inner {
    align-self: center;
    margin: 0 !important;
}
.primary--text i {
    color: #2196f3 !important;
    caret-color: #2196f3 !important;
}
.error--text i {
    color: #ff5252 !important;
    caret-color: #ff5252 !important;
}

::v-deep div.v-slider__thumb-label {
    transform: none !important;
    border: none !important;
    background-color: transparent !important;
    bottom: -36px;
    left: -16px;
}

::v-deep div.v-slider__thumb-label > * {
    transform: none !important;
}

::v-deep .v-slider__track-container {
    height: 4px;
}

::v-deep .option-text-field .v-input__append-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;
}
::v-deep .option-text-field .v-input__prepend-inner {
    margin-top: auto !important;
    margin-bottom: auto !important;
}

::v-deep .option-text-field .v-input__slot {
    min-height: 42px !important;
}

::v-deep .v-messages {
    height: 2px !important;
    min-height: 0 !important;
    line-height: 2px !important;
}

.pricing-type-btn {
    text-transform: none;
    width: 50%;
    box-shadow: none;
    font-size: 18px;
    font-weight: 400;
}

.options-header {
    font-weight: 400;
    padding: 12px 0px;
    font-size: 18px;
}

.options-card {
    background-color: rgba(230, 230, 230, 0.03);
    border-radius: 12px;
    padding: 18px
}

.nav-btn {
    margin-right: 15px;
    text-transform: none;
    background-color: transparent;
    align-self: end;
    font-size: 14px;
    border-radius: 6px;
}

.disabled {
    opacity: 0.5;
}

.partition-break {
    height: 1px;
    background-color: #9f9f9f;
    margin: 30px 0;
}
.date-styles {
    width: 40%;
    padding-left: 10px;
    height: 80px;
}
.border-class {
    border-left: 1px solid #9f9f9f;
    padding-left: 16px;
}
.centered-text ::v-deep input {
    text-align: center;
}

.custom-slider {
    width: 90%;
}
.slider-values {
    width: 90%;
    position: relative;
    height: 20px;
}
.slider-values span {
    position: absolute;
    width: 12%;
    top: 0;
    transform: translateX(-50%);
    font-size: 14px;
    font-weight: bold;
    color: #fff;
}

</style>
