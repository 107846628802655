<template>
    <v-navigation-drawer
        :app="is_mobile ? false : true"
        :fixed="is_mobile ? true : false"
        :class="collapsed ? 'show' : 'hide'"
        clipped
        stateless
        hide-overlay
        :floating="is_mobile ? false : true"
        :temporary="false"
        :disable-resize-watcher="true"
        :mobile-breakpoint="800"
        :value="!collapsed"
        class="drawer pt-4"
        color="#201F1F"
        width="360px"
        style="color: #ffffff !important"
    >
        <template v-slot:prepend>
            <div class="d-flex flex-column pl-6 pr-2">
                <div
                    class="d-flex flex-row align-items-center justify-space-between pr-1 py-2 mt-3"
                >
                    <div class="font-weight-medium d-flex">
                        <div
                            @click="reloadPage"
                            style="font-size: 27px; cursor: pointer"
                        >
                            FreightGPT
                        </div>
                        <v-chip
                            color="#1DD3C0"
                            small
                            class="font-weight-medium text-subtitle-1 betaChip px-2 mt-5"
                            style="
                                position: absolute;
                                top: 3.8rem;
                                left: 1.5rem;
                            "
                            >Beta</v-chip
                        >
                    </div>

                    <v-spacer />

                    <!-- Inner btn -->
                    <svg
                        @click="toggle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        class="toggleBtn"
                        :class="collapsed ? 'hide' : 'show'"
                        style="
                            position: static;
                            align-self: center;
                            margin-right: 0.8rem;
                            justify-self: end;
                        "
                        :style="
                            !is_mobile
                                ? {
                                      justifySelf: 'end'
                                  }
                                : {
                                      position: 'absolute',
                                      right: '1rem',
                                      transform: 'translateX(50%)'
                                  }
                        "
                    >
                        <g
                            fill="none"
                            stroke="rgb(220,220,220)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        >
                            <path
                                d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2Z"
                            />
                            <path d="M7.25 10L5.5 12l1.75 2m2.25 7V3" />
                        </g>
                    </svg>

                    <!-- Outer btn -->
                    <svg
                        @click="toggle"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 24 24"
                        class="toggleBtn"
                        :class="collapsed ? 'show' : 'hide'"
                        style="visibility: visible !important"
                        :style="
                            !is_mobile
                                ? {
                                      top: 'calc(3% + 1rem)',
                                      position: 'absolute',
                                      right: '-4.8rem'
                                  }
                                : {
                                      top: '-1.5rem',
                                      right: '-2.2rem',
                                      position: 'relative',
                                      transform: 'translateX(50%)'
                                  }
                        "
                    >
                        <g
                            fill="none"
                            stroke="rgb(220,220,220)"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                        >
                            <path
                                d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2Zm-9.5 0V3"
                            />
                            <path d="m5.5 10l1.75 2l-1.75 2" />
                        </g>
                    </svg>
                </div>
            </div>
        </template>
        <div
            class="d-flex flex-column align-center justify-start pt-6 px-2"
        >
            <v-btn
                class="text-none font-weight-light py-4 pl-4 mt-5"
                outlined
                min-width="92%"
                max-width="92%"
                height="fit-content"
                :ripple="false"
                style="
                    justify-content: start;
                    border-width: 0.4px !important;
                    border-color: #3181b6;
                    font-size: 0.9rem;
                "
                :style="[
                    newSessionBlocked
                        ? { color: '#999999 !important' }
                        : { color: '#ffffff !important' }
                ]"
                :disabled="isLoading || curConvInd === null"
                @click="newConversation"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    version="1.1"
                    viewBox="0 0 100 115"
                    xml:space="preserve"
                    style="width: 28px; height: 28px; margin-right: 8px;"
                >
                    <path
                        d="M10,58.1v9.3c0,1.1,0.5,2.1,1.2,2.9c0.7,0.7,1.7,1.1,2.7,1.1c1,0,2-0.4,2.8-1.2l11.8-11.8h35.7c5.8,0,10.4-4.7,10.4-10.4V16.4c0-5.8-4.7-10.4-10.4-10.4h-52c-5.8,0-10.4,4.7-10.4,10.4v31.5C1.7,52.9,5.3,57.1,10,58.1z M25.8,28.6h9l0-9h6.9l0,9l9,0v6.9l-9,0v9h-6.9v-9h-9V28.6z M98.3,39.1v31.5c0,5-3.6,9.2-8.3,10.2v9.3c0,2.2-1.8,3.9-3.9,3.9c-1,0-2-0.4-2.8-1.1L71.5,81.1H35.8c-5.6,0-10.1-4.4-10.4-9.8l6-6h1.2v5.4c0,1.7,1.4,3.2,3.2,3.2h38.7l8.2,8.2v-8.2h5.2c1.7,0,3.2-1.4,3.2-3.2V39.1c0-1.7-1.4-3.2-3.2-3.2h-6.3v-7.3h6.3C93.6,28.7,98.3,33.4,98.3,39.1z"
                        :fill="newSessionBlocked ? '#999999' : 'rgb(220, 220, 220)'"
                    />
                </svg>
                New Request
            </v-btn>
            <v-progress-linear
                v-if="isLoadingConvs"
                indeterminate
                style="width: 100%; position: absolute; top: 12rem"
            />
            <v-list
                style="
                    overflow-y: scroll !important;
                    width: 90%;
                    padding-right: 0.4rem;
                "
                density="compact"
                id="convList"
                :class="isConvListOverflown ? 'showScroll' : ''"
            >
                <v-list-item-group
                    :key="convHistKey"
                    v-model="curConvInd"
                    color="indigo"
                >
                    <div
                        v-for="[index, item] of conversationHistory.entries()"
                        :key="index"
                        class="d-flex flex-row pt-1"
                        style="
                            width: 100%;
                            position: relative;
                            overflow-x: hidden;
                            overflow-y: hidden;
                        "
                    >
                        <v-hover v-slot="{ hover }">
                            <div
                                :class="hover ? 'hovered' : ''"
                                class="convListItemWrapper pa-0 flex-grow-1 d-flex"
                                style="border-radius: 0.2rem; max-width: 100%"
                            >
                                <v-list-item
                                    ref="convListItem"
                                    @click="retrieveConversation(item.id)"
                                    :disabled="
                                        isLoading || index === curConvInd
                                    "
                                    class="pl-3 pr-0 flex-grow-1 overflow-hidden"
                                    style="border-radius: 0.2rem"
                                >
                                    <v-list-item-title
                                        style="
                                            color: #eeeeee;
                                            overflow: hidden;
                                            text-overflow: ellipsis;
                                            white-space: flex-nowrap;
                                        "
                                    >
                                        {{
                                            item.summary !== ''
                                                ? item.summary
                                                : 'New Conversation'
                                        }}
                                    </v-list-item-title>
                                </v-list-item>
                                <v-icon
                                    class="convDeleteButton"
                                    v-if="hover"
                                    @click.stop="
                                        confirmDeletion(
                                            index,
                                            conversationHistory[index].id
                                        )
                                    "
                                    color="#EEEEEE"
                                    medium
                                    style="
                                        width: 2rem;
                                        flex-shrink: 0;
                                        flex-basis: auto;
                                    "
                                    >mdi-delete-outline</v-icon
                                >
                            </div>
                        </v-hover>
                    </div>
                </v-list-item-group>
            </v-list>
        </div>

        <template v-slot:append>
            <SideBarFooter
                :user_info="user_info"
                :is_mobile="is_mobile"
            />
        </template>
        <v-dialog
            v-if="confirmDeletionWindow"
            v-model="confirmDeletionWindow"
            width="28rem"
        >
            <v-card dark>
                <v-card-title class="text-h6">Confirm Deletion</v-card-title>
                <v-card-text class="text-subtitle-1 pt-2 pb-2">
                    Would you like to delete
                    {{ conversationHistory[toDelete[0]].summary }}?
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn @click="abortDeletion()" class="text-none">
                        Cancel
                    </v-btn>
                    <v-btn
                        @click="deleteConversation()"
                        class="red darken-2 text-none"
                    >
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Outside click helper -->
        <div
            v-if="!collapsed"
            :style="{
                height: windowHeight + 'px',
                width: computedOutsideWidth + 'px',
                right: -computedOutsideWidth + 'px'
            }"
            style="color: red; position: fixed; top: 0"
            @click="toggle"
        />
    </v-navigation-drawer>
</template>

<script lang="ts">
import Vue from 'vue';
import { UserInterface } from '@/types/userObject';
import { makePostRequest } from '../conversation-utils';
import { Auth } from 'aws-amplify';
import { isDevEnv } from '../resources';
import * as analytics from '../analytics/pinpoint';
import SideBarFooter from './SideBarFooter.vue';

interface ConversationObject {
    conv_id: {
        S: string;
    };
    creation_timestamp: {
        N: string;
    };
    summary: {
        S: string;
    };
}

export default Vue.extend({
    name: 'SideBar',

    components: {
        SideBarFooter
    },

    props: [
        'user_info',
        'waiting',
        'new_convo_summary',
        'resetCurConvIndValue',
        'is_mobile'
    ],

    watch: {
        waiting(val) {
            this.isLoading = val;
        },
        new_convo_summary(val) {
            if (val != null) {
                this.setConvSummary(val);
            }
        },
        resetCurConvIndValue() {
            if (this.resetCurConvIndValue) {
                this.curConvInd = null;
                this.$emit('resetEmitFunc');
            }
        }
    },

    computed: {
        computedOutsideWidth() {
            if (this.$el && this.is_mobile) {
                const viewportWidth = window.innerWidth;
                const drawerRect = this.$el.getBoundingClientRect();
                return viewportWidth - drawerRect.width - drawerRect.right;
            }
            return 0;
        },

        newSessionBlocked() {
            const val =
                (this as any).isLoading || (this as any).curConvInd === null;
            this.$emit('newSessionBlocked', val);
            return val;
        }
    },

    async created() {
        this.isLoading = true;
        try {
            this.getConversationHistory().then((result) => {
                this.conversationHistory = result;
                this.isLoading = false;
            });
        } catch (error) {
            this.isLoading = false;
            console.error(error);
        }
        window.addEventListener('resize', this.updateWidth);
        this.$emit('ready', this);
    },

    mounted() {
        this.updateWidth();

        this.convListElement = document.getElementById('convList') as Element;
        window.addEventListener('resize', this.convlistResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.convlistResize);
    },

    updated() {
        this.isConvListOverflown =
            this.convListElement.scrollHeight >
            this.convListElement.clientHeight;
    },

    data: function () {
        const itemsEnd = [
            {
                icon: 'mdi-cog',
                text: 'Settings',
                enabled: true,
                action: 'account'
            }
        ];

        let endModel = -1;

        return {
            windowHeight: window.innerHeight,
            convHistKey: 0,
            isLoading: false,
            isLoadingConvs: true,
            convListElement: null as any,
            isConvListOverflown: false,
            chatHistory: [] as any[],
            itemsEnd: itemsEnd.filter((item) => item.enabled) as any[],
            modelEnd: endModel,
            collapsed: this.is_mobile,
            customWidth: '300px',
            conversationHistory: [] as any[],
            curConvInd: null as number | null,
            user: {} as UserInterface,
            confirmDeletionWindow: false,
            toDelete: [] as any[],
            hoveredIndex: null as number | null
        };
    },

    methods: {
        setConvSummary(new_summary: String) {
            let clean_summary = new_summary.replace(/['"]/g, '');
            this.conversationHistory[0].summary = '';
            let i = 0;
            const interval = setInterval(() => {
                if (i < clean_summary.length) {
                    this.conversationHistory[0].summary +=
                        clean_summary.charAt(i);
                    i++;
                } else {
                    clearInterval(interval);
                }
            }, 25);
        },

        convlistResize() {
            this.isConvListOverflown =
                this.convListElement.scrollHeight >
                this.convListElement.clientHeight;
        },

        filterNav(items: any[]) {
            return items.filter((item) => item.enabled);
        },

        menuActionClick(action: any) {
            console.log(action);
        },

        include() {
            return [document.querySelector('.sideBarBtn')];
        },

        toggle() {
            this.collapsed = !this.collapsed;
            this.$emit('updateCollapseValue', this.collapsed);
        },

        reloadPage() {
            location.reload();
        },

        appendConvToHistory(conv_id: string) {
            if (
                this.conversationHistory.filter((elem) => elem.id === conv_id)
                    .length === 0
            ) {
                this.conversationHistory.unshift({
                    id: conv_id,
                    summary: `New Conversation`
                });
                this.curConvInd = 0;
                this.convHistKey += 1;
            }
        },

        updateWidth() {
            let pixels = (20 / 100) * window.innerWidth;
            this.customWidth = pixels.toFixed(0) + 'px';
        },

        openSettings(account: string) {
            console.log(account);
        },

        async getConversationHistory() {
            let params = {
                user_id: this.user_info.sub
            };
            this.isLoading = true;
            try {
                const result = await makePostRequest(params, isDevEnv());
                this.isLoading = false;
                this.isLoadingConvs = false;
                if (result.status === 200 && result.data.body) {
                    const sorted = result.data.body.sort(
                        (a: ConversationObject, b: ConversationObject) => {
                            return (
                                parseInt(a.creation_timestamp.N) -
                                parseInt(b.creation_timestamp.N)
                            );
                        }
                    );
                    return sorted.reverse().map((element: any) => {
                        return {
                            id: element.conv_id.S,
                            summary: element.summary.S.replace(/['"]/g, '')
                        };
                    });
                } else {
                    throw new Error('Invalid response');
                }
            } catch (error) {
                console.error('Error retrieving conversation history');
                return [];
            }
        },

        retrieveConversation(conversation_id: string) {
            this.$emit('clearTokenWarning', false);
            this.$emit('setLoadingSplash', true);
            let params = {
                user_id: this.user_info.sub,
                conversation_id: conversation_id
            };
            this.isLoading = true;
            this.$emit('clearMessages');
            makePostRequest(params, isDevEnv()).then((result: any) => {
                if (this.is_mobile) {
                    this.toggle();
                }
                this.isLoading = false;
                if (result.status === 200 && result.data.body.length > 0) {
                    this.$emit(
                        'loadConversation',
                        result.data.body[0].History.map((element: any) => {
                            if ('event' in element) {
                                if (
                                    (element.event.type === 'windy' ||
                                        element.event.type ===
                                            'pricer_polyline' ||
                                        element.event.type === 'gmaps' ||
                                        element.event.type === 'polygon') &&
                                    element.event.data.active_type === 'active'
                                ) {
                                    return {
                                        type: 'vis_event',
                                        event_data: element.event,
                                        id: element.message_id
                                    };
                                } else if (
                                    element.event.type === 'transit_markers' ||
                                    element.event.type ===
                                        'carrier_search_markers'
                                ) {
                                    return {
                                        type: 'markers',
                                        event_data: element.event,
                                        id: element.message_id
                                    };
                                } else {
                                    return {
                                        type: element.event.type,
                                        event_data: element.event,
                                        id: element.message_id
                                    };
                                }
                            } else if (
                                element.type == 'human' ||
                                element.type == 'ai'
                            ) {
                                return {
                                    type: element.type,
                                    message: element.data.content,
                                    id: element.data.id
                                };
                            } else {
                                return null;
                            }
                        }),
                        conversation_id
                    );
                    this.$emit('setLoadingSplash', false);
                }
            });
        },

        confirmDeletion(index: number, conversation_id: string) {
            this.confirmDeletionWindow = true;
            this.toDelete = [index, conversation_id];
        },

        abortDeletion() {
            this.confirmDeletionWindow = false;
            this.hoveredIndex = null;
            this.toDelete = [];
        },

        deleteConversation() {
            let [index, conversation_id] = this.toDelete;
            this.toDelete = [];
            this.confirmDeletionWindow = false;
            let params = {
                user_id: this.user_info.sub,
                conversation_id: conversation_id,
                delete: true
            };
            this.isLoading = true;
            makePostRequest(params, isDevEnv()).then((result: any) => {
                this.isLoading = false;
                if (result.status === 200) {
                    if (this.curConvInd === index) {
                        this.newConversation();
                    } else if (
                        this.curConvInd != null &&
                        this.curConvInd > index
                    ) {
                        this.curConvInd -= 1;
                    }
                    this.conversationHistory.splice(index, 1);
                } else {
                    console.log('Error removing conversation');
                }
            });
        },

        newConversation() {
            this.$emit('clearMessages');
            this.$emit('newConv', true);
            this.curConvInd = null;
            this.hoveredIndex = null;
            if (this.is_mobile) {
                this.collapsed = true;
                this.$emit('updateCollapseValue', this.collapsed);
            }
        },

        async signOut() {
            try {
                analytics.sendEvent(
                    'logout',
                    this.user_info.sub,
                    this.user_info.name,
                    this.user_info.email
                );

                await Auth.signOut({ global: true });
                this.$router.push({
                    name: 'landing'
                });
            } catch (error) {
                console.log('error signing out: ', error);
            }
        }
    }
});
</script>

<style scoped>
.rotate-135 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.betaChip {
    border-radius: 6px;
    color: white;
}

.drawer {
    overflow: visible !important;
}
.drawer.show {
    transform: 0;
    transition: all 0.3s ease-out !important;
    -webkit-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
}
.drawer.hide {
    transform: translateX(-100%);
    transition: all 0.3s ease-out !important;
    -webkit-transition: all 0.3s ease-out !important;
    -o-transition: all 0.3s ease-out !important;
    -moz-transition: all 0.3s ease-out !important;
}
.toggleBtn.show {
    opacity: 1;
    z-index: 50;
    width: min-content;
    transition: opacity 0.2s 0.3s ease-in;
    -webkit-transition: opacity 0.2s 0.3s ease-in;
    -o-transition: opacity 0.2s 0.3s ease-in;
    -moz-transition: opacity 0.2s 0.3s ease-in;
    cursor: pointer;
}
.toggleBtn.hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease-in;
    -webkit-transition: opacity 0.2s ease-in;
    -o-transition: opacity 0.2s ease-in;
    -moz-transition: opacity 0.2s ease-in;
}

.hovered:not(:has(.v-list-item--active)) {
    background-color: rgba(222, 222, 222, 0.2) !important;
}
.convListItemWrapper:has(.v-list-item--active) {
    background-color: rgba(48, 56, 80, 1) !important;
}
.convListItemWrapper:has(.v-list-item--active) .convDeleteButton {
    background-color: rgb(63, 81, 181, 0.12);
}

.showScroll::-webkit-scrollbar {
    width: 0.4rem !important;
}
.showScroll::-webkit-scrollbar-thumb {
    border-radius: 0.2rem;
}
.showScroll::-webkit-scrollbar-track {
    margin-top: 0.8rem;
    border-radius: 0.2rem;
}
</style>
