<template>
    <div class="header-container">
        <span class="icon-container">
            <span style="display: flex">
                <svg
                    width="25"
                    height="25"
                    viewBox="0 0 79 71"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M68.6342 10.0969C65.9379 7.15751 61.0742 6.97723 57.8331 10.2184L26.0879 41.9636C25.2649 42.7866 25.2649 43.8291 26.0879 44.6521C26.9109 45.4751 27.9534 45.4751 28.7764 44.6521L55.0348 18.3937C55.7739 17.6798 56.7639 17.2848 57.7915 17.2937C58.8191 17.3027 59.8021 17.7148 60.5287 18.4415C61.2554 19.1681 61.6675 20.1511 61.6765 21.1787C61.6854 22.2063 61.2904 23.1963 60.5765 23.9354L34.3181 50.1938C33.4232 51.1146 32.3527 51.8466 31.17 52.3465C29.9872 52.8463 28.7162 53.1039 27.4321 53.1039C26.1481 53.1039 24.8771 52.8463 23.6943 52.3465C22.5116 51.8466 21.4411 51.1146 20.5462 50.1938C19.6253 49.2989 18.8933 48.2284 18.3935 47.0456C17.8936 45.8629 17.6361 44.5919 17.6361 43.3078C17.6361 42.0238 17.8936 40.7528 18.3935 39.57C18.8933 38.3872 19.6253 37.3167 20.5462 36.4219L52.2914 4.67668C58.4327 -1.46856 68.4187 -1.66843 74.3562 4.73547C80.4388 10.8846 80.6151 20.8197 74.2386 26.7337L37.0615 63.9108C28.4786 72.4938 15.0202 72.4938 6.43722 63.9108C-2.14574 55.3279 -2.14574 41.8695 6.43722 33.2865L38.1824 1.54136C38.9216 0.827451 39.9115 0.432422 40.9391 0.441351C41.9667 0.450281 42.9497 0.862454 43.6763 1.5891C44.403 2.31574 44.8152 3.29871 44.8241 4.32631C44.833 5.3539 44.438 6.34389 43.7241 7.08305L11.9789 38.8282C6.45289 44.3542 6.45289 52.8431 11.9789 58.3691C17.5049 63.8952 25.9938 63.8952 31.5198 58.3691L68.7518 21.1371L68.8733 21.0196C71.8127 18.3271 71.993 13.4595 68.7518 10.2184L68.6342 10.0969Z"
                        fill="#B1B1B1"
                    />
                    <path
                        d="M68.6342 10.0969C65.9379 7.15751 61.0742 6.97723 57.8331 10.2184L26.0879 41.9636C25.2649 42.7866 25.2649 43.8291 26.0879 44.6521C26.9109 45.4751 27.9534 45.4751 28.7764 44.6521L55.0348 18.3937C55.7739 17.6798 56.7639 17.2848 57.7915 17.2937C58.8191 17.3027 59.8021 17.7148 60.5287 18.4415C61.2554 19.1681 61.6675 20.1511 61.6765 21.1787C61.6854 22.2063 61.2904 23.1963 60.5765 23.9354L34.3181 50.1938C33.4232 51.1146 32.3527 51.8466 31.17 52.3465C29.9872 52.8463 28.7162 53.1039 27.4321 53.1039C26.1481 53.1039 24.8771 52.8463 23.6943 52.3465C22.5116 51.8466 21.4411 51.1146 20.5462 50.1938C19.6253 49.2989 18.8933 48.2284 18.3935 47.0456C17.8936 45.8629 17.6361 44.5919 17.6361 43.3078C17.6361 42.0238 17.8936 40.7528 18.3935 39.57C18.8933 38.3872 19.6253 37.3167 20.5462 36.4219L52.2914 4.67668C58.4327 -1.46856 68.4187 -1.66843 74.3562 4.73547C80.4388 10.8846 80.6151 20.8197 74.2386 26.7337L37.0615 63.9108C28.4786 72.4938 15.0202 72.4938 6.43722 63.9108C-2.14574 55.3279 -2.14574 41.8695 6.43722 33.2865L38.1824 1.54136C38.9216 0.827451 39.9115 0.432422 40.9391 0.441351C41.9667 0.450281 42.9497 0.862454 43.6763 1.5891C44.403 2.31574 44.8152 3.29871 44.8241 4.32631C44.833 5.3539 44.438 6.34389 43.7241 7.08305L11.9789 38.8282C6.45289 44.3542 6.45289 52.8431 11.9789 58.3691C17.5049 63.8952 25.9938 63.8952 31.5198 58.3691L68.7518 21.1371L68.8733 21.0196C71.8127 18.3271 71.993 13.4595 68.7518 10.2184L68.6342 10.0969Z"
                        fill="#B1B1B1"
                    />
                    <path
                        d="M68.6342 10.0969C65.9379 7.15751 61.0742 6.97723 57.8331 10.2184L26.0879 41.9636C25.2649 42.7866 25.2649 43.8291 26.0879 44.6521C26.9109 45.4751 27.9534 45.4751 28.7764 44.6521L55.0348 18.3937C55.7739 17.6798 56.7639 17.2848 57.7915 17.2937C58.8191 17.3027 59.8021 17.7148 60.5287 18.4415C61.2554 19.1681 61.6675 20.1511 61.6765 21.1787C61.6854 22.2063 61.2904 23.1963 60.5765 23.9354L34.3181 50.1938C33.4232 51.1146 32.3527 51.8466 31.17 52.3465C29.9872 52.8463 28.7162 53.1039 27.4321 53.1039C26.1481 53.1039 24.8771 52.8463 23.6943 52.3465C22.5116 51.8466 21.4411 51.1146 20.5462 50.1938C19.6253 49.2989 18.8933 48.2284 18.3935 47.0456C17.8936 45.8629 17.6361 44.5919 17.6361 43.3078C17.6361 42.0238 17.8936 40.7528 18.3935 39.57C18.8933 38.3872 19.6253 37.3167 20.5462 36.4219L52.2914 4.67668C58.4327 -1.46856 68.4187 -1.66843 74.3562 4.73547C80.4388 10.8846 80.6151 20.8197 74.2386 26.7337L37.0615 63.9108C28.4786 72.4938 15.0202 72.4938 6.43722 63.9108C-2.14574 55.3279 -2.14574 41.8695 6.43722 33.2865L38.1824 1.54136C38.9216 0.827451 39.9115 0.432422 40.9391 0.441351C41.9667 0.450281 42.9497 0.862454 43.6763 1.5891C44.403 2.31574 44.8152 3.29871 44.8241 4.32631C44.833 5.3539 44.438 6.34389 43.7241 7.08305L11.9789 38.8282C6.45289 44.3542 6.45289 52.8431 11.9789 58.3691C17.5049 63.8952 25.9938 63.8952 31.5198 58.3691L68.7518 21.1371L68.8733 21.0196C71.8127 18.3271 71.993 13.4595 68.7518 10.2184L68.6342 10.0969Z"
                        fill="#B1B1B1"
                    />
                </svg>
            </span>
            <span class="xlsx-icon-wrapper" style="display: flex">
                <v-icon size="25" color="rgb(124,196,1)">mdi-file-document</v-icon>
            </span>
            <span>{{ fileName }}</span>
            <span style="margin-left: 30px; display: flex" class="info-tooltip">
                <svg
                    width="25"
                    height="25"
                    viewBox="0 0 90 90"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="cursor: pointer;"
                    @click="$emit('changeCurrentState', 'upload')"
                >
                    <rect width="90" height="90" fill="url(#pattern0_364_609)" />
                    <defs>
                        <pattern
                            id="pattern0_364_609"
                            patternContentUnits="objectBoundingBox"
                            width="1"
                            height="1"
                        >
                            <use xlink:href="#image0_364_609" transform="scale(0.0111111)" />
                        </pattern>
                        <image
                            id="image0_364_609"
                            width="90"
                            height="90"
                            xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAACXBIWXMAAAsTAAALEwEAmpwYAAADaklEQVR4nO2cvU5UQRSABylUIBb6AEqjvIU/haC1aAmEBH0Dy6U0BgtwifTGxBj0HcBALAwFYA/ESo1UGqXgMyc7JIbcuXch7Jk7c8+XbEPBnPl27tyZ2TnHOcMwDMMwDMMwDMMwDKNrgGHgCfAG2AB+AAfE48DHsOFjktiGk/xKgXPAQ+AjcEj9kRhXgXGJ3aUAcAf4QrpsA7dcXQEuAIvkwSHwEjjv6gRwBfhEfqxL31yNJMvjlivb0WX76aKbkbwDzAN3gRvAYMSYB30Moz4mia2bkR1vGuliTt4FJur8Jgf6gUlgr6Iv7ZirizLeAQMuEeiM9OWKF+TtGKOgbAn3HOhziQH0AXMV87Xe0wk8KgnmfYqSj8l+W9K/caeF3/GF5uRkpouKaSQ0Z686xbOL0LZ6wmUCMBXoo/T9mkYAcghTxE6dVxenfA/JE1rEjEYAcuJVxLzLDDrb8CJeazQux4tFjLrMAO4F+vpZo3E5yy3iussMOjvIIr5pNP430PiQywxgKNDXPxqN7xc0/NNlCgE0Gm4XtLvgMoWIoi8CS8Av/3klf3OZQizRx7aqyW61kxHdFDDROpjoMwB4Su9onUWM2UBvZJtkBdkmWUG2SVaQbZIVZPdOMjALPHMZwslk91zyEU2W3dKS3GTZLW3JTZTdiiW5SbJbsSUfMesyhI6D2bpIznpk102yyVaUbLIVJZtsRckmW1HyEfaCPAkEONE/Maox0Uo0VTTa1yuaJhoY8BeGfvvPksqFoQaKXoySCtdA0ftRLnU27NrupZjXdu0iOjoX0UOpFWMuM4D7MVMrLFkInWShsvS3fpdX+ttezPS3soTOSZcJwHSgj9L3q1pBSKGnIvZi1uI44yShr4E+rmgGIhXAQnxIOYOWzlZbSmCEeKBdbq2svM9cimkXdCS/KOnXpvogkpJlFTXtllOaRuhMF/I0hpC+3qxbrvT/c/ZUnVcjfnUxXTInx0/xk4JOvrBTFbv+SxkDRmJu1/3IHfF53u0uaikJa9Fr4PlybFvkyxZw2dUBL7ubkZ0aa7WRfGwaaSdS9LUK6cNC9OmiDClZlnhlx81oq4tTrrPH/Q7ykPojMa7IZiTZzZYUegIey4mXHC/68+zQjwcaSNvffSwS04za2YVhGIZhGIZhGIZhGIbLg39+D0CHNhcJ0QAAAABJRU5ErkJggg=="
                        />
                    </defs>
                </svg>
                <span class="info-tooltip-text">Return to upload</span>
            </span>
        </span>
        <span style="display: flex; align-items: start; color: #9b9b9b">
            Upload Complete
            <span class="circle"></span>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'UploadedFileBar',
        props: [
            'file_name'
        ],
        computed: {
            fileName() {
                return this.file_name.split('/').pop();
            }
        },
    };
</script>

<style scoped>
.header-container {
    font-weight: 400;
    justify-content: space-between;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 1rem;
    border-bottom: 1px solid #dde1e7;
}
.icon-container {
    display: flex;
    align-items: center;
}
.xlsx-icon-wrapper {
    margin: 0px 12px;
}
.circle {
    display: inline-block;
    margin: 4px 0px 0px 10px;
    width: 15px;
    height: 15px;
    background-color: #1dd3c0;
    border-radius: 50%;
}

.info-tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.info-tooltip .info-tooltip-text {
    visibility: hidden;
    width: max-content;
    background-color: #333333;
    color: #dddddd;
    text-align: center;
    border-radius: 6px;
    padding: 6px 12px;
    position: absolute;
    z-index: 7;
    top: 125%; /* Position the tooltip below the text */
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 14px;
}

.info-tooltip:hover .info-tooltip-text {
    visibility: visible;
    opacity: 1;
}
</style>
