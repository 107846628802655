<template>
    <div style="display: flex; min-height: 400px; width: 100%; height: (100% - 64px); flex-direction: column; position: relative; flex-grow: 2">
        <div class="table-container">
            <header style="padding: 20px 20px 25px 0px; font-weight: 700; font-size: 18px;" class="column-map-header">
                <span style="flex-grow: 0; margin-right: 24px;">
                    Column Mapping
                </span>
                <v-btn
                    class="nav-btn"
                    style="border-color: rgb(113, 163, 193); max-width: fit-content;"
                    small
                    outlined
                    @click.stop="resetMappingData()"
                >
                    Reset Mappings
                </v-btn>
                <span style="margin-left: auto; margin-right: 18px; height: 22px; flex-grow: 0">
                    Validation Status:
                    <v-icon :color="validationStatusColor">{{ validationStatusIcon }}</v-icon>
                </span>
            </header>
            <body style="display: flex;">
                <table class="custom-table">
                    <thead>
                        <tr style="background-color: transparent;">
                            <th style="text-align: start; font-weight: 400; padding-left: 12px; padding-bottom: 5px;">Column</th>
                            <th style="text-align: start; font-weight: 400; padding-left: 12px; padding-bottom: 5px;">Mapped Column</th>
                            <th/>
                            <th style="font-weight: 400; padding-bottom: 5px; width: 120px;">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="[key, value] in Object.entries(columnMapping)" :key="key" class="custom-table-row">
                            <td style="font-weight: 360; margin-bottom: 6px; width: min-content; padding-left: 20px;">{{ columnLabels[key] }}{{ value.required ? '  *' : '' }}</td>
                            <td style="position: relative; padding-left: 12px;" :id="'map' + key">
                                <v-autocomplete
                                    clearable
                                    style="margin-top: 2px;"
                                    :items="sourceColumns"
                                    :value="value.sourceCol"
                                    @input="validateColumnMapping($event, key)"
                                    outlined single-line hide-details
                                    :error="!!value.error && value.required"
                                    :menu-props="{
                                        ...dropdownMenuStyle,
                                        'attach':'#map' + key
                                    }"
                                />
                            </td>
                            <td style="width: auto">
                                <span style="color: #e45f60" v-if="value.required">
                                    {{ value.error }}
                                </span>
                            </td>
                            <td style="text-align: center">
                                <div v-if="value.required">
                                    <v-icon color="rgb(224, 224, 224)" v-if="changedColumnFields.includes(key) && (value.sourceCol || !value.required)">
                                        mdi-help-circle
                                    </v-icon>
                                    <v-icon color="rgb(115, 252, 252)" v-else-if="!value.error && value.sourceCol">
                                        mdi-check-circle
                                    </v-icon>
                                    <v-icon color="red" v-else-if="(!value.sourceCol && value.required) || value.error">
                                        mdi-close-circle
                                    </v-icon>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </body>
        </div>
        <div style="display: flex; justify-content: flex-end; height: fit-content; padding-top: 12px; margin-top: auto;">
            <v-btn
                class="nav-btn"
                style="border-color: #FFC456"
                outlined
                @click="$emit('changeCurrentState', 'upload')"
            >
                <v-icon size="22" style="margin-right: 8px">mdi-skip-previous-outline</v-icon>
                Back
            </v-btn>
            <v-btn
                class="nav-btn"
                :style="canSubmit ? { 'border-color': '#1DD3C0' } : {}"
                outlined
                @click="validateAndProceed"
                :disabled="!canSubmit"
            >
                Next
                <v-icon size="22" style="margin-left: 8px">mdi-skip-next-outline</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { isDevEnv, APIEndpoints } from '../../resources';
import './rfp-shared-styles.css';
import _ from 'lodash';

const BUCKET_PATH = `s3://${process.env.VUE_APP_RFP_BUCKET_NAME}/`;

export default {
    name: 'ValidationStep',
    props: {
        user_id: {
            type: String,
            required: true,
        },
        rfp_session_id: {
            type: String,
            required: true,
        },
        uploaded_file_path: {
            type: String,
            required: true,
        },
        saved_mapping_data: {
            type: Object,
            required: true,
        },
        websocket: {
            type: Object,
            required: true,
        },
    },
    computed: {
        validationStatus() {
            let validationFlag = true;
            for (const value of Object.values(this.columnMapping)) {
                if (value.required === true) {
                    if (!value.sourceCol || value.error) {
                        validationFlag = false;
                    }
                }
            }
            if (validationFlag) {
                return true;
            } else {
                return false;
            }
        },
        validationStatusColor() {
            if (!this.validationStatus) return 'red';
            if (this.changedColumnFields.length > 0) return 'rgb(224, 224, 224)';
            return 'rgb(115, 252, 252)';
        },
        validationStatusIcon() {
            if (!this.validationStatus) return 'mdi-close-circle';
            if (this.changedColumnFields.length > 0) return 'mdi-help-circle';
            return 'mdi-check-circle';
        },
        canSubmit() {
            for (const value of Object.values(this.columnMapping)) {
                if (value.required === true) {
                    if (!value.sourceCol) {
                        return false;
                    }
                }
            }
            return true
        },
        equipmentTypePanelSuffix() {
            return `(From column "${this.saved_mapping_data.columnMapping.equipment_type.sourceCol}")`;
        },
    },
    data() {
        /* eslint-disable-next-line no-unused-vars */
        const mappingData = _.cloneDeep(this.saved_mapping_data);
        delete mappingData.columnMapping.equipment_type
        return {
            validationEndpoint: isDevEnv() ? APIEndpoints.dev.rfp_validation_endpoint : APIEndpoints.prod.rfp_validation_endpoint,
            displayError: false,
            errorMessage: '',
            delayValidation: true,
            columnLabels: {
                'origin_city': 'Origin City',
                'origin_state': 'Origin State',
                'destination_city': 'Destination City',
                'destination_state': 'Destination State',
                'equipment_type': 'Equipment Type',
                'distance_miles': 'Distance (mi)',
                'origin_zip': 'Origin Zip',
                'destination_zip': 'Destination Zip',
            },
            newMappingData: null,
            changedColumnFields: [],
            columnMapping: _.cloneDeep(mappingData.columnMapping), // needs retrieval from backend
            sourceColumns: _.cloneDeep(mappingData.sourceColumns),
            dropdownMenuStyle: {
                'offset-y': true,
                'content-class': 'elevation-2',
                'content-props': {
                    'dense': true
                }
            },
            mappedFileName: '',
        };
    },
    methods: {
        validateAndProceed() {
            // Make validation request, and if successful emit to parent to proceed to next step
            this.$emit('loading', 'Validating Columns');
            if (this.changedColumnFields.length === 0 && this.validationStatus === true) {
                this.$emit('updateRemainderColumns', this.getRemainderColumns());
                this.$emit('proceedToEquipment')
            } else {
                this.sendValidationRequest();
            }
        },
        resetMappingData(){
            let newVal = _.cloneDeep(this.saved_mapping_data);
            this.columnMapping = newVal.columnMapping;
            this.sourceColumns = newVal.sourceColumns;
            this.changedColumnFields = [];
        },
        getRemainderColumns() {
            let selectedColumns = Object.values(this.columnMapping).reduce((acc, value) => {
                if (value.sourceCol) {
                    acc.push(value.sourceCol)
                }
                return acc
            }, [])
            return this.sourceColumns.filter((value) => {
                return !(value in selectedColumns)
            })
        },
        handleValidationResponse(response) {
            let responseData = JSON.parse(response.data);
            if ('close' in responseData) {
                this.$emit('loading', false);
                if (this.validationStatus) {
                    this.$nextTick(() => {
                        this.$emit('updateColumnMapping', this.newMappingData);
                        this.$emit('updateRemainderColumns', this.getRemainderColumns());
                        this.$emit('proceedToEquipment')
                    });
                } else {
                    this.$emit('sendAnalyticsEvent', 'column_validation_fail')
                }
            } else if ('fatalError' in responseData && responseData.fatalError) {
                this.newMappingData = null;
                this.$emit('sendAnalyticsEvent', 'column_fatal_fail')
                this.$emit('displayError', responseData.fatalError);
                this.$emit('changeCurrentState', 'upload');
            } else if ('mappedFilename' in responseData) {
                this.mappedFilePath = responseData.mappedFilename;
                this.$emit('updateMappedFilePath', this.mappedFilePath);
                this.columnMapping = {...this.columnMapping, ...responseData.columnMapping};
                this.sourceColumns = responseData.sourceColumns;
                this.newMappingData = responseData;
                this.changedColumnFields = [];
            }
        },
        sendValidationRequest() {
            const columnMapPayload = {};
            for (const [key, value] of Object.entries(this.columnMapping)) {
                if (value.sourceCol) {
                    columnMapPayload[value.sourceCol] = key;
                }
            }
            const messageObject = {
                action: this.validationEndpoint,
                rfp_session_id: this.rfp_session_id,
                user_id: this.user_id,
                sourceFilename: BUCKET_PATH + this.uploaded_file_path,
                ...Object.keys(columnMapPayload).length > 0 && { userSpecifiedColumnMapping: columnMapPayload },
            };
            return this.websocket.sendMessage(messageObject, this.handleValidationResponse)
        },
        validateColumnMapping(event, key) {
            let oldValue, toSwap;
            for (const [k, v] of Object.entries(this.columnMapping)) {
                if (k === key) {
                    oldValue = v.sourceCol;
                }
                if (v.sourceCol === event && event !== null) {
                    toSwap = k;
                }
            }
            if (toSwap && this.columnMapping[toSwap]) {
                this.$set(this.columnMapping[toSwap], 'sourceCol', oldValue);
            }
            if (event === this.saved_mapping_data.columnMapping[key].sourceCol) {
                this.changedColumnFields = this.changedColumnFields.filter((field) => field !== key);
            } else {
                this.changedColumnFields.push(key);
            }
            if (event === null) {
                this.$set(this.columnMapping[key], 'sourceCol', null);
            } else {
                this.$set(this.columnMapping[key], 'sourceCol', event);
            }
            this.$set(this.columnMapping[key], 'error', null);
        },
    },
    mounted() {
        this.changedColumnFields = [];
    },
};
</script>

<style scoped>
.draggable-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 6px;
    border: 1px solid #919191;
    border-radius: 6px;
    margin-top: 6px;
}
.draggable-item {
    padding: 0 6px;
    margin: 6px;
    flex-grow: 0;
    height: fit-content;
    background-color: #DA9406;
    border: 1px solid #DA9406;
    border-radius: 6px;
    cursor: grab;
}
.custom-disable {
    opacity: 0.4
}
.custom-disable ::v-deep * {
    cursor: default !important;
}
.custom-table {
    height: fit-content;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}
.custom-table td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.custom-table td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.custom-table tr:nth-child(even) {
    background-color: transparent;
}
.custom-table tr:nth-child(odd) {
    background-color: rgba(230, 230, 230, 0.04);
}
.custom-table td {
    padding: 6px;
}

::v-deep .v-select-list {
    margin-left: 24px;
}

.nav-btn {
    margin-right: 15px;
    text-transform: none;
    background-color: transparent;
    align-self: end;
    font-size: 14px;
    border-radius: 6px;
}

.table-container {
    overflow-y: auto;
    height: 100%;
    background-color: transparent;
    min-height: none !important;
}
.expansion-panel-container::-webkit-scrollbar-corner {
    display: none;
}
.expansion-panel-container::-webkit-scrollbar {
    width: 0.5rem !important;
}
.expansion-panel-container::-webkit-scrollbar-track {
    background: #434141;
    border-radius: 0.815rem;
}
.expansion-panel-container::-webkit-scrollbar-thumb {
    border-radius: 0.815rem;
    background-color: #656565;
}

.column-map-header {
    display: flex;
}
.column-map-header ::v-deep .v-expansion-panel-header__icon {
    margin-left: 0;
}


/* These are all overrides of default Vuetify styling */
::v-deep .v-input {
    margin-top: 12px;
}
::v-deep .v-input__append-inner {
    margin-top: 0px !important;
}
::v-deep .v-expansion-panel::before {
    display: none;
}
::v-deep .v-expansion-panel {
    margin-top: 0;
}
::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px;
}
::v-deep .v-text-field--outlined .v-input__slot {
    min-height: 0 !important;
}
::v-deep .v-text-field .v-input__append-inner {
    align-self: center;
}
::v-deep .v-text-field .v-input__prepend-inner {
    align-self: center;
    margin-top: 0;
}
::v-deep .cursor-default input {
    cursor: default;
}
</style>
