<template>
    <div style="display: flex; min-height: 400px; width: 100%; height: (100% - 64px); flex-direction: column; position: relative; flex-grow: 2">
        <div style="background-color: transparent">
            <header style="padding: 20px 20px 25px 0px; font-weight: 700; font-size: 18px;">
                Equipment Type Mapping
            </header>
            <body>
                <div style="display: flex; margin-bottom: 12px;">
                    <table class="custom-table">
                        <tbody>
                            <tr class="custom-table-row">
                                <td style="font-weight: 360; margin-bottom: 6px; width: min-content; padding-left: 20px;">Equipment Type Column</td>
                                <td style="position: relative; padding-left: 12px;" :id="'mapEquipCol'">
                                    <v-autocomplete
                                        clearable
                                        style="margin-top: 2px;"
                                        :items="source_columns"
                                        :value="equipmentTypeColumn"
                                        @change="handleEquipmentColumnChange($event)"
                                        outlined single-line hide-details
                                        :menu-props="{
                                            ...dropdownMenuStyle,
                                            'attach':'#mapEquipCol'
                                        }"
                                    />
                                </td>
                                <td style="width: auto">
                                    <span style="color: #e45f60">
                                        {{ equipmentColumnError }}
                                    </span>
                                </td>
                                <td style="text-align: center; width: 120px;">
                                    <div>
                                        <v-icon color="rgb(224, 224, 224)" v-if="equipmentColumnChanged">
                                            mdi-help-circle
                                        </v-icon>
                                        <v-icon color="rgb(115, 252, 252)" v-else-if="!equipmentColumnError && equipmentTypeColumn">
                                            mdi-check-circle
                                        </v-icon>
                                        <v-icon color="red" v-else-if="!equipmentTypeColumn || equipmentColumnError">
                                            mdi-close-circle
                                        </v-icon>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style="display: flex; height: fit-content; justify-content: space-between">
                    <div style="display: flex; flex-direction: row; flex-wrap: wrap; width: 48%">
                        <section
                            v-for="[canonicalType, sourceTypes] in Object.entries(equipmentMapping)"
                            :key="canonicalType"
                            style="
                                display: flex;
                                flex-direction: column;
                                position: relative;
                                flex-grow: 1 !important;
                                margin-right: 12px;
                                width: 32%;
                            "
                        >
                            <div style="font-weight: 480; margin-bottom: 6px; user-select: none;">{{ canonicalType }}</div>
                            <draggable
                                :list="equipmentMapping[canonicalType]"
                                class="draggable-container"
                                tag="div"
                                group="equip"
                                style="background-color: rgb(21, 21, 21);"
                            >
                                <div v-for="element in sourceTypes" class="draggable-item" :key="element.id">
                                    {{ element }}
                                </div>
                            </draggable>
                        </section>
                    </div>
                    <section style="width: 48%; display: flex; flex-direction: column;">
                        <div style="font-weight: 480; margin-bottom: 6px;">Other</div>
                        <draggable
                            :list="unusedSourceEquipTypes"
                            group="equip"
                            class="draggable-container"
                            tag="div"
                        >
                            <div v-for="element in unusedSourceEquipTypes" class="draggable-item" :key="element.id">
                                {{ element }}
                            </div>
                        </draggable>
                    </section>
                </div>
            </body>
        </div>
        <div style="display: flex; justify-content: flex-end; padding-top: 12px; margin-top: auto;">
            <v-btn
                class="nav-btn"
                style="border-color: #FFC456"
                outlined
                @click="$emit('changeCurrentState', 'validate')"
            >
                <v-icon size="22" style="margin-right: 8px">mdi-skip-previous-outline</v-icon>
                Back
            </v-btn>
            <v-btn
                class="nav-btn"
                outlined
                @click="validateAndProceed"
                :disabled="!equipmentTypeColumn || !!equipmentColumnError"
                :style="!equipmentTypeColumn || !!equipmentColumnError ? {} : { 'border-color': '#1DD3C0' }"
            >
                Next
                <v-icon size="22" style="margin-left: 8px">mdi-skip-next-outline</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { isDevEnv, APIEndpoints } from '../../resources';
import draggable from "vuedraggable";
import './rfp-shared-styles.css';
import _ from 'lodash';

const BUCKET_PATH = `s3://${process.env.VUE_APP_RFP_BUCKET_NAME}/`;

export default {
    name: 'EquipmentStep',
    components: {
        draggable,
    },
    props: {
        uploaded_file_path: {
            type: String,
            required: true,
        },
        source_columns: {
            type: Array,
            required: true,
        },
        saved_mapping_data: {
            type: Object,
            required: true,
        },
        websocket: {
            type: Object,
            required: true,
        },
        rfp_session_id: {
            type: String,
            required: true
        }
    },
    watch: {
        equipmentMapping: {
            handler: function() {
                this.equipmentMappingChanged = true;
            },
            deep: true,
        },
        unusedSourceEquipTypes: {
            handler: function() {
                this.equipmentMappingChanged = true;
            },
            deep: true,
        },
    },
    data() {
        /* eslint-disable-next-line no-unused-vars */
        const mappingData = _.cloneDeep(this.saved_mapping_data);
        const { Other = [], ...equipmentMappingCopy } = mappingData.equipmentTypeMapping;
        return {
            equipmentValidationEndpoint: isDevEnv() ? APIEndpoints.dev.rfp_equipment_endpoint : APIEndpoints.prod.rfp_equipment_endpoint,
            displayError: false,
            errorMessage: '',
            delayValidation: true,
            newEquipmentMapping: null,
            equipmentMappingChanged: false,
            equipmentTypeColumn: mappingData.equipmentColumnMapping,
            equipmentColumnChanged: false,
            equipmentColumnError: '',
            equipmentMapping: equipmentMappingCopy, // needs retrieval from backend
            unusedSourceEquipTypes: Other,
            dropdownMenuStyle: {
                'offset-y': true,
                'content-class': 'elevation-2',
                'content-props': {
                    'dense': true
                }
            },
            mappedFileName: '',
        };
    },
    methods: {
        validateAndProceed() {
            // Make validation request, and if successful emit to parent to proceed to next step
            if (!this.equipmentMappingChanged) {
                this.$emit('proceedToPrice')
            } else {
                this.validateEquipmentMapping();
            }
        },
        resetMappingData(){
            let newVal = _.cloneDeep(this.saved_mapping_data);
            this.columnMapping = newVal.columnMapping;
            const { Other, ...equipmentMappingCopy } = newVal.equipmentTypeMapping;
            this.equipmentMapping = equipmentMappingCopy;
            this.unusedSourceEquipTypes = Other;
        },
        handleValidationResponse(response) {
            let responseData = JSON.parse(response.data);
            if ('close' in responseData) {
                this.$emit('loading', false);
                this.loading = false;
                this.$nextTick(() => {
                    this.$emit('updateEquipmentMapping', this.newEquipmentMapping);
                    this.$emit('proceedToPrice')
                });
            } else if ('fatalError' in responseData && responseData.fatalError) {
                this.newMappingData = null;
                this.$emit('displayError', responseData.fatalError);
            } else if ('equipmentTypeMapping' in responseData) {
                this.mappedFilePath = responseData.equipFilename;
                this.$emit('updateEquipFilePath', this.mappedFilePath);
                /* eslint-disable-next-line no-unused-vars */
                const { Other = [], ...equipmentMappingCopy } = responseData.equipmentTypeMapping;
                this.equipmentMapping = equipmentMappingCopy;
                this.unusedSourceEquipTypes = Other;
                this.newEquipmentMapping = responseData;
                this.equipmentMappingChanged = false;
            }
        },
        validateEquipmentMapping() {
            this.$emit('loading', 'Validating Equipment');
            const equipmentMapPayload = {};
            for (const [canonicalType, sourceTypes] of Object.entries(this.equipmentMapping)) {
                for (const sourceType of sourceTypes) {
                    equipmentMapPayload[sourceType] = canonicalType;
                }
            }
            for (const sourceType of this.unusedSourceEquipTypes) {
                equipmentMapPayload[sourceType] = 'Other';
            }
            const messageObject = {
                action: this.equipmentValidationEndpoint,
                rfp_session_id: this.rfp_session_id,
                user_id: this.user_id,
                sourceFilename: BUCKET_PATH + this.uploaded_file_path,
                ...Object.keys(equipmentMapPayload).length > 0 && { userSpecifiedEquipmentColumn: this.equipmentTypeColumn },
                ...Object.keys(equipmentMapPayload).length > 0 && { userSpecifiedEquipmentTypeMapping: equipmentMapPayload },
            };
            return this.websocket.sendMessage(messageObject, this.handleValidationResponse)
        },
        handleColumnChangeResponse(response) {
            let responseData = JSON.parse(response.data);
            if ('close' in responseData) {
                this.$emit('loading', false);
                this.loading = false;
            } else if ('fatalError' in responseData && responseData.fatalError) {
                this.newMappingData = null;
                this.$emit('displayError', responseData.fatalError);
            } else if ('equipmentTypeMapping' in responseData) {
                this.mappedFilePath = responseData.equipFilename;
                this.$emit('updateEquipFilePath', this.mappedFilePath);
                this.$emit('updateEquipmentMapping', responseData);
                const { Other = [], ...equipmentMappingCopy } = responseData.equipmentTypeMapping;
                this.equipmentMapping = equipmentMappingCopy;
                this.unusedSourceEquipTypes = Other;
                this.equipmentTypeColumn = responseData.equipmentColumnMapping;
                this.newEquipmentMapping = responseData;
                this.equipmentMappingChanged = false;
            }
        },
        handleEquipmentColumnChange(value) {
            if (value !== null) {
                this.loading = true
                this.equipmentColumnChanged = true;
                this.$emit('loading', 'Looking for equipment types');
                this.websocket.sendMessage({
                    action: this.equipmentValidationEndpoint,
                    rfp_session_id: this.rfp_session_id,
                    user_id: this.user_id,
                    sourceFilename: BUCKET_PATH + this.uploaded_file_path,
                    userSpecifiedEquipmentColumn: value,
                }, this.handleColumnChangeResponse);
            } else {
                this.equipmentTypeColumn = null;
                this.equipmentColumnError = '';
                this.unusedSourceEquipTypes = [];
                for (const entry in this.equipmentMapping) {
                    this.equipmentMapping[entry] = [];
                }
            }
        }
    },
    mounted() {
    },
};
</script>

<style scoped>
.draggable-container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    padding: 6px;
    border: 1px solid #919191;
    border-radius: 6px;
    margin-top: 6px;
}
.draggable-item {
    padding: 0 6px;
    margin: 6px;
    flex-grow: 0;
    height: fit-content;
    background-color: #DA9406;
    border: 1px solid #DA9406;
    border-radius: 6px;
    cursor: grab;
}
::v-deep .v-select-list {
    margin-left: 24px;
}

.nav-btn {
    margin-right: 15px;
    text-transform: none;
    background-color: transparent;
    align-self: end;
    font-size: 14px;
    border-radius: 6px;
}

.expansion-panel-container {
    overflow-y: auto;
    max-height: 100%;
}
.expansion-panel-container::-webkit-scrollbar-corner {
    display: none;
}
.expansion-panel-container::-webkit-scrollbar {
    width: 0.5rem !important;
}
.expansion-panel-container::-webkit-scrollbar-track {
    background: #434141;
    border-radius: 0.815rem;
}
.expansion-panel-container::-webkit-scrollbar-thumb {
    border-radius: 0.815rem;
    background-color: #656565;
}

.column-map-header ::v-deep .v-expansion-panel-header__icon {
    margin-left: 0;
}


/* These are all overrides of default Vuetify styling */
::v-deep .v-input {
    margin-top: 12px;
}
::v-deep .v-input__append-inner {
    margin-top: 0px !important;
}
::v-deep .v-expansion-panel::before {
    display: none;
}
::v-deep .v-expansion-panel {
    margin-top: 0;
}
::v-deep .v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 48px;
}
::v-deep .v-text-field--outlined .v-input__slot {
    min-height: 0 !important;
}
::v-deep .v-text-field .v-input__append-inner {
    align-self: center;
}
::v-deep .v-text-field .v-input__prepend-inner {
    align-self: center;
    margin-top: 0;
}
::v-deep .cursor-default input {
    cursor: default;
}

.custom-table {
    height: fit-content;
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
}
.custom-table td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.custom-table td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.custom-table tr:nth-child(even) {
    background-color: transparent;
}
.custom-table tr:nth-child(odd) {
    background-color: rgba(230, 230, 230, 0.04);
}
.custom-table td {
    padding: 6px;
}
</style>
