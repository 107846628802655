import AWS from "aws-sdk";
import { Readable } from 'stream';

// Consider refreshing your temporary AWS credentials before calling these
class S3Utils {
    readFileFromS3(bucketName: string, filePath: string): Promise<Blob> {
        // Note: filePath should not start with 's3://{bucketName}/'
        if (filePath.startsWith("s3://")) {
            filePath = filePath.slice(5);
        }
        if (filePath.startsWith(bucketName)) {
            filePath = filePath.slice(bucketName.length + 1);
        }

        return new Promise((resolve, reject) => {
            const params = {
                Bucket: bucketName,
                Key: filePath,
            };

            new AWS.S3({region: "us-east-1"}).getObject(params, (err, data) => {
                if (err) {
                    console.error('Error reading file from S3');
                    reject('Error reading file from S3');
                } else {
                    if (data.Body && Buffer.isBuffer(data.Body)) {
                        const blob = new Blob([data.Body], { type: data.ContentType });
                        resolve(blob);
                    } else if (data.Body instanceof require('stream').Readable) {
                        const chunks: Buffer[] = [];
                        const stream = data.Body as Readable;
                        stream.on('data', (chunk: Buffer) => {
                            chunks.push(chunk);
                        });

                        stream.on('end', () => {
                            const buffer = Buffer.concat(chunks);
                            try {
                                const blob = new Blob([buffer], { type: data.ContentType || 'application/octet-stream' });
                                resolve(blob);
                            } catch (blobError) {
                                reject(blobError);
                            }
                        });

                        stream.on('error', () => {
                            console.error('Error reading file stream from S3');
                            reject('Error reading file stream from S3');
                        });
                    }
                }
            });
        });
    }

    uploadFileToS3(bucketName: string, filePath: string, file: File): Promise<void> {
        return new Promise((resolve, reject) => {
            const params = {
                Bucket: bucketName,
                Key: filePath,
                Body: file,
            };

            new AWS.S3({region: "us-east-1"}).putObject(params, (err: any, data: any) => {
                if (err) {
                    console.error('Error uploading file to S3', err);
                    reject('Error uploading file to S3');
                } else {
                    resolve(data);
                }
            });
        });
    }
}

export default S3Utils;
