import AWS from 'aws-sdk';
import { Auth } from 'aws-amplify';

let refreshTimerId: ReturnType<typeof setTimeout> | null = null

AWS.config.update({
    region: 'us-east-1',
});

export function storeAuthSession(user_id_token: string) {
    // User ID Token is the JWT ID token that is returned by Cognito: user.signInUserSession.idToken.jwtToken
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL as string,
        Logins: {
            [process.env.VUE_APP_COGNITO_USER_POOL as string]: user_id_token,
        },
    });
}

type CognitoIdToken = ReturnType<typeof Auth.currentSession> extends Promise<infer T>
  ? T extends { getIdToken: () => infer U }
    ? U
    : never
  : never;

export function storeTokenWithRefresh(freshIdToken?: CognitoIdToken) {
    if (freshIdToken !== undefined) {
        // Forgo currentsession call if a fresh id token is provided
        const expiration = freshIdToken.payload.exp
        if (expiration !== undefined) {
            const expiresInMs = (expiration * 1000) - Date.now();
            const refreshTime = expiresInMs - 5000;
            const refreshDelay = 4000;
            storeAuthSession(freshIdToken.getJwtToken())
            if (refreshTime > 0) {
                clearRefreshTimer()
                refreshTimerId = setTimeout(() => {
                    storeTokenWithRefresh();
                }, refreshTime + refreshDelay);
            } else {
                clearRefreshTimer()
                refreshTimerId = setTimeout(() => {
                    storeTokenWithRefresh();
                }, refreshDelay);
            }
        }
    } else {
        Auth.currentSession().then((session) => {
            const idToken = session.getIdToken()
            const expiration = idToken.payload.exp
            if (expiration !== undefined) {
                const expiresInMs = (expiration * 1000) - Date.now();
                const refreshTime = expiresInMs - 5000;
                const refreshDelay = 4000;
                storeAuthSession(idToken.getJwtToken())
                if (refreshTime > 0) {
                    clearRefreshTimer()
                    refreshTimerId = setTimeout(() => {
                        storeTokenWithRefresh();
                    }, refreshTime + refreshDelay);
                } else {
                    clearRefreshTimer()
                    refreshTimerId = setTimeout(() => {
                        storeTokenWithRefresh();
                    }, refreshDelay);
                }
            }
        }).catch(() => {
            console.error("Login session could not be retrieved")
        })
    }

}

export function clearRefreshTimer() {
    if (refreshTimerId) {
        clearTimeout(refreshTimerId);
        refreshTimerId = null
    }
}

export function refreshAWSCredentials(): Promise<void> {
    return new Promise((resolve, reject) => {
        (AWS.config.credentials as AWS.Credentials)?.refresh((error: AWS.AWSError | undefined) => {
            if (error) {
                reject('Error refreshing AWS credentials');
            } else {
                resolve();
            }
        });
    });
}
