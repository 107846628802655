<template>
    <section>
        <div class="loader">
            <div class="circle circle1"></div>
            <div class="circle circle2"></div>
            <div class="circle circle3"></div>
            <div class="circle circle4"></div>
            <div class="circle circle5"></div>
            <div class="circle circle6"></div>
            <div class="circle circle7"></div>
            <div class="circle circle8"></div>
            <div class="circle circle9"></div>
        </div>
        <div class="loading-message">{{ capitalizedLoadingMessage }}<span class="dots"/></div>
    </section>
</template>

<script>
export default {
    name: 'RFPLoader',
    props: {
        loading_message: {
            type: String,
            default: 'Loading',
        },
    },
    computed: {
        capitalizedLoadingMessage() {
            const msg = this.loading_message.toLowerCase();
            return msg.charAt(0).toUpperCase() + msg.slice(1);
        },
    },
};
</script>

<style scoped>
.loader {
    display: flex;
    position: relative;
    width: 340px;
    height: 340px;
    align-items: center;
    justify-content: center;
}

.circle {
  position: absolute;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}
.circle1, .circle2, .circle3, .circle4, .circle5, .circle6, .circle7, .circle8, .circle9{
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 100%);
}
.circle1 {
  width: 40px;
  height: 40px;
  border: 4px solid transparent;
  border-color: #BABABA;
  animation-duration: 1.8s;
  transform: rotate(0deg);
}

.circle2 {
  width: 70px;
  height: 70px;
  border: 4px solid transparent;
  border-color: #ff6f61;
  animation-duration: 2.1;
  transform: rotate(45deg);
}

.circle3 {
  width: 100px;
  height: 100px;
  border: 4px solid transparent;
  border-color: #81c784;
  animation-duration: 2.4s;
  transform: rotate(90deg);
}

.circle4 {
  width: 130px;
  height: 130px;
  border: 4px solid transparent;
  border-color: #4fc3f7 ;
  animation-duration: 2.7s;
  transform: rotate(135deg);
}

.circle5 {
  width: 160px;
  height: 160px;
  border: 4px solid transparent;
  border-color: #BABABA;
  animation-duration: 1.4s;
  transform: rotate(180deg);
}
.circle6 {
  width: 190px;
  height: 190px;
  border: 4px solid transparent;
  border-color: #ff6f61;
  animation-duration: 4.1s;
  transform: rotate(225deg);
}
.circle7 {
  width: 220px;
  height: 220px;
  border: 4px solid transparent;
  border-color: #81c784;
  animation-duration: 3.8s;
  transform: rotate(270deg);
}
.circle8 {
  width: 250px;
  height: 250px;
  border: 4px solid transparent;
  border-color: #4fc3f7 ;
  animation-duration: 6.5s;
  transform: rotate(315deg);
}
.circle9 {
  width: 280px;
  height: 280px;
  border: 4px solid transparent;
  border-color: #BABABA;
  animation-duration: 5s;
  transform: rotate(360deg);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-message {
    font-weight: 700;
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #BABABA;
}
.dots {
    display: inline-block;
}

.dots::after {
    content: '';
    animation: dots 3s steps(4, end) infinite;
}

@keyframes dots {
    0%,100% {
        content: '';
    }
    25% {
        content: '.';
    }
    50% {
        content: '..';
    }
    75% {
        content: '...';
    }
}
</style>
