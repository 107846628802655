<template>
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column;">
        <v-btn
            :ripple="false"
            class="download-spreadsheet-btn"
            @click="downloadTemplate"
        >Download Spreadsheet Template
            <v-icon style="padding-left: 10px;" size="20" color="white">
                mdi-tray-arrow-down
            </v-icon>
        </v-btn>
        <div class="container-section">
            <div class="left-line"></div>
            <div class="text">OR</div>
            <div class="right-line"></div>
        </div>
        <div style="max-height: 80%; flex-grow: 3">
            <label class="file-upload" :for="'upload' + componentUid" id="drop-zone" @drop="uploadDropHandler($event)" @dragover="uploadDragOverHandler($event)">
                <div v-if="fileDragged" class="file-upload-content" style="border: 0.2rem solid;">
                    <v-icon x-large color="#A6A6A6">mdi-cloud-upload</v-icon>
                    <div style="padding-top: 15px;">
                        Drop your file here
                    </div>
                </div>
                <div v-else-if="!uploadedFile" class="file-upload-content">
                    <v-icon x-large color="#A6A6A6">mdi-cloud-upload</v-icon>
                    <div style="padding-top: 15px;">
                        Click or drag and drop to upload your spreadsheet
                    </div>
                </div>
                <div v-else style="display: flex;flex-direction: column;width: 100%;justify-content: center;align-items: center;height: 50vh; color: #A6A6A6; font-size: 20px;">
                    {{ uploadedFile.name }}
                </div>
                <input type="file" accept=".xlsx, .csv" :id="'upload' + componentUid" @change="handleUpload" style="display: none;" />
            </label>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import S3Utils from '../../utils/s3-utils';
import { isDevEnv,APIEndpoints } from '@/resources';
import { downloadFile } from '../../utils/file-utils';
import { refreshAWSCredentials } from '../../utils/aws-cognito-auth';

const BUCKET_PATH = `s3://${process.env.VUE_APP_RFP_BUCKET_NAME}/`;

export default {
    name: 'UploadStep',
    props: {
        websocket: {
            type: Object,
            required: true
        },
        user_id: {
            type: String,
            required: true,
        },
        saved_file_name: {
            type: String,
            default: null,
        },
        s3_utils: {
            type: S3Utils,
            required: true,
        },
        rfp_session_id: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            validationEndpoint: isDevEnv() ? APIEndpoints.dev.rfp_validation_endpoint : APIEndpoints.prod.rfp_validation_endpoint,
            uploadedFilePath: null,
            fileDragged: false,
            dragCounter: 0,
            uploadedFile: null,
            componentUid: uuidv4(),
            columnMapping: {
                "origin_city": {
                    "sourceCol": null,
                    "required": true,
                    "error": null
                },
                "origin_state": {
                    "sourceCol": null,
                    "required": true,
                    "error": null
                },
                "destination_city": {
                    "sourceCol": null,
                    "required": true,
                    "error": null
                },
                "destination_state":{
                    "sourceCol": null,
                    "required": true,
                    "error": null
                },
                "equipment_type": {
                    "sourceCol": null,
                    "required": true,
                    "error": null
                },
                "origin_close_date": {
                    "sourceCol": null,
                    "required": false,
                    "error": null
                },
                "distance_miles": {
                    "sourceCol": null,
                    "required": false,
                    "error": null
                },
                "origin_zip": {
                    "sourceCol": null,
                    "required": false,
                    "error": null
                },
                "destination_zip": {
                    "sourceCol": null,
                    "required": false,
                    "error": null
                },
            }, // needs retrieval from backend
            equipmentMapping: {
                'Dry Van': [],
                'Reefer': [],
            }, // needs retrieval from backend
            sourceHeaders: [],
            unusedSourceEquipTypes: [],
        };
    },
    methods: {
        downloadTemplate() {
            const csvContent = 'origin_city,origin_state,destination_city,destination_state,equipment_type,distance_miles,origin_zip,destination_zip\n'
            const blob = new Blob([csvContent], { type: 'text/csv' });
            downloadFile(blob, 'rfp_template.csv');
        },
        uploadDropHandler(event) {
            event.preventDefault();

            if (event.dataTransfer.items) {
                [...event.dataTransfer.items].forEach((item) => {
                if (item && item.kind === "file") {
                    const file = item.getAsFile()
                    this.uploadedFile = file;
                }
                });
            } else {
                // Use DataTransfer interface to access the file(s)
                [...event.dataTransfer.files].forEach((file) => {
                    this.uploadedFile = file;
                });
            }
            const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
            this.fileDragged = false;
            if (validTypes.includes(this.uploadedFile.type)
                || (this.uploadedFile.type === 'application/vnd.ms-excel' && this.uploadedFile.name.split('.').pop() === 'csv')) {
                this.submitUpload();
            } else {
                this.uploadedFile = null;
                this.$emit('displayError', 'Invalid file type. Please upload an Excel or CSV file.');
            }
        },
        generateUploadedFilePath(prefix='raw_') {
            return this.user_id + '/' + prefix + Date.now() + this.uploadedFile.name.replaceAll(/\s/g, "");
        },
        submitUpload() {
            this.$emit('loading', 'Uploading');
            if (this.uploadedFile) {
                this.uploadedFilePath = this.generateUploadedFilePath('raw_');
                this.$emit('updateUploadedFilePath', this.uploadedFilePath);
                this.$emit('updateUploadedFileName', this.uploadedFile.name);
                refreshAWSCredentials(this.user_id_token).then(() => {
                    this.s3_utils.uploadFileToS3(process.env.VUE_APP_RFP_BUCKET_NAME, this.uploadedFilePath, this.uploadedFile)
                        .then(() => {
                            this.$emit('loading', 'Validating Columns');
                            this.sendValidationRequest();
                        })
                        .catch((err) => {
                            this.$emit('loading', false);
                            if (err.type === 'CredentialsError' && err.message.includes('Missing credentials in config')) {
                                this.$emit('displayError', 'Your session may have expired, please refresh the page and try again.');
                            } else {
                                throw err
                            }
                        });
                }).catch(() => {
                    console.error('Error refreshing credentials');
                    this.$emit('loading', false);
                    this.$emit('displayError', 'Failed to upload file. Please check your network connection and try again.');
                    this.$emit('sendAnalyticsEvent', 'upload_fail')
                });
            }
        },
        handleValidationResponse(response) {
            let responseData = JSON.parse(response.data);
            if ('fatalError' in responseData && responseData.fatalError) {
                this.$emit('loading', false);
                this.$emit('displayError', responseData.fatalError);
            } else if ('mappedFilename' in responseData) {
                this.$emit('loading', false);
                this.$emit('validationResponse', responseData);
            }
        },
        sendValidationRequest() {
            const columnMapPayload =
                Object.entries(this.columnMapping).reduce((acc, current) => {
                    if (current[1].sourceCol) {
                        acc[current[1].sourceCol] = current[0];
                    }
                    return acc;
                }, {});
            const messageObject = {
                action: this.validationEndpoint,
                rfp_session_id: this.rfp_session_id,
                user_id: this.user_id,
                sourceFilename: BUCKET_PATH + this.uploadedFilePath,
                ...Object.keys(columnMapPayload).length > 0 && { userSpecifiedColumnMapping: columnMapPayload },
            };
            return this.websocket.sendMessage(messageObject, this.handleValidationResponse)
        },
        uploadDragOverHandler(event) {
            event.preventDefault();
        },
        handleUpload(event) {
            const file = event.target.files[0];
            const validTypes = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv'];
            if (validTypes.includes(file.type)
            || (file.type === 'application/vnd.ms-excel' && file.name.split('.').pop() === 'csv')) {
                this.uploadedFile = file;
                this.submitUpload();
            } else {
                this.uploadedFile = null;
                this.$emit('displayError', 'Invalid file type. Please upload an Excel or CSV file.');
            }
        },
    },
    mounted() {
        if (this.saved_file_name) {
            this.uploadedFile = { name: this.saved_file_name };
        }

        window.addEventListener('dragenter', (event) => {
            if (event.dataTransfer && event.dataTransfer.types && event.dataTransfer.types.includes('Files')) {
                this.dragCounter++;
                if (this.dragCounter > 0) {
                    this.fileDragged = true;
                }
            }
        });
        window.addEventListener('dragleave', (event) => {
            if (event.dataTransfer && event.dataTransfer.types && event.dataTransfer.types.includes('Files')) {
                this.dragCounter--;
                if (this.dragCounter === 0) {
                    this.fileDragged = false;
                }
            }
        });
    },
    beforeDestroy() {
        window.removeEventListener('dragenter', () => {});
        window.removeEventListener('dragleave', () => {});
    },
    watch:{
        columnMapping: {
            handler() {
                this.validationStatus = false;
            },
            deep: true
        },
        equipmentMapping: {
            handler(val) {
                this.validationStatus = false;
                if ('Other' in val) {
                    this.unusedSourceEquipTypes = val.Other;
                    delete val.Other;
                }
            },
            deep: true
        },
    }
};
</script>

<style scoped>
.file-upload {
    height: 100% !important;
    background-color: #313131;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.file-upload-content {
    margin: 16px;
    flex-grow: 2;
    width: -moz-available;
    width: -webkit-fill-available;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.download-spreadsheet-btn{
    background-color: #1E1E1E !important;
    border-color: #1E1E1E;
    border-radius: 10px;
    text-transform: none;
    width: 100%;
    height: 55px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: transparent !important;
    color: transparent !important;
}
.v-btn:before {
    background-color: transparent !important;
}
.v-btn--is-elevated {
    box-shadow: none !important;
}

.container-section {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1% 0px;
}
.left-line {
    flex: 1;
    height: 1px;
    background-color: grey;
    margin: 0 10px 0 0;
}
.right-line {
    flex: 1;
    height: 1px;
    background-color: grey;
    margin: 0 0 0 10px;
}
.text{
    color: grey
}
</style>
