<template>
    <v-app style="background: #201f1f; color: white; overflow: hidden">
        <transition name="fade">
            <v-alert
                v-if="showAlert"
                outlined
                type="warning"
                prominent
                border="left"
                class="maintenance-alert"
                style="z-index: 1000; position: fixed; left: 12px; bottom: 12px; width: calc(100vw - 24px);"
                :style="{background: isMobile?'rgb(32, 31, 31) !important':''}" >
                <v-row align="center">
                    <v-col class="grow">
                        {{ alert_message }}
                    </v-col>
                    <v-col class="shrink" style="padding: 0px;">
                    <v-btn
                        style="
                            background: transparent;
                        "
                        :ripple="false"
                        @click="showAlert=false"
                        >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    </v-col>
                </v-row>
            </v-alert>
        </transition>
        <SideBar
            v-if="!isMobile && isUserLoaded"
            ref="sideBar"
            class="sidebar"
            :user_info="user_info"
            :waiting="sideBarLoading"
            :new_convo_summary="summaryShow"
            :resetCurConvIndValue="resetCurConvIndValue"
            :user_name="user_info.name"
            :is_mobile="isMobile"
            @clearTokenWarning="clearTokenWarning"
            @newSessionBlocked="newSessionBlocked = $event"
            @resetEmitFunc="resetEmitFunc"
            @loadConversation="loadConversation"
            @newConv="newSession"
            @setLoadingSplash="setLoadingSplash"
            @updateCollapseValue="updateCollapseValue"
            @ready="setRefInstance"
            @clearMessages="clearMessages"
            style="z-index: 30;"
        />
        <v-main style="overflow: hidden;">
            <SideBar
                v-if="isMobile && isUserLoaded"
                ref="sidebar"
                class="sidebar"
                :user_info="user_info"
                :waiting="sideBarLoading"
                :new_convo_summary="summaryShow"
                :resetCurConvIndValue="resetCurConvIndValue"
                :user_name="user_info.name"
                :is_mobile="isMobile"
                @clearTokenWarning="clearTokenWarning"
                @newSessionBlocked="newSessionBlocked = $event"
                @resetEmitFunc="resetEmitFunc"
                @loadConversation="loadConversation"
                @newConv="newSession"
                @setLoadingSplash="setLoadingSplash"
                @updateCollapseValue="updateCollapseValue"
                @ready="setRefInstance"
                @clearMessages="clearMessages"
                style="z-index: 6; position: fixed !important; height: 100%"
            />
            <v-container
                dense
                fluid
                ref="pageContainer"
                id="pageContainer"
                class="px-0"
                :style="{ 'z-index': collapsed || isMobile ? '4' : '9', 'justify-content': 'flex-end' }"
                :class="isMobile ? 'mobile' : ''"
            >
                <span v-if="!isMobile" style="display: flex;width: 100%;justify-content: right;margin:10px 94px 5px 0px; padding-top: 10px; font-size: 18px; font-weight: 700;">
                    <span class="active-rfp-btn" style="">Chat</span>
                    <span class="inactive-rfp-btn" style="" @click="$router.push({name: 'bulk_pricing'})">Bulk Pricing</span>
                </span>
                <div
                    v-if="isNewConv"
                    style="
                        width: 72%;
                        margin: 0 auto;
                        position: absolute;
                        top: 10%;
                        display: flex;
                        flex-direction: column;
                        color: #e9e9e9;
                        padding-bottom: 2rem;
                        font-size: 2rem;
                    "
                    :style="isMobile ? { top: '16%', width: '100%' } : {}"
                >
                    <NewConversationDiagram @example-clicked="handleExampleClick" />
                </div>
                <v-container
                    id="logContainer"
                    class="d-flex flex-column flex-grow-1 align-items-center pa-0"
                    :class="[
                        active ? 'active' : '',
                        isMobile ? 'mobile' : '',
                        $vuetify.breakpoint.md ? 'medium' : ''
                    ]"
                >
                    <!-- for debugging vars on mobile -->
                    <!-- <div style="position: absolute; bottom: 20px; left: 20px; z-index: 300;"></div> -->
                    <div
                        style="
                            position: absolute;
                            top: 0;
                            width: 100%;
                            display: flex;
                            justify-content: end;
                        "
                        :style="[
                            isMobile
                                ? { position: 'relative' }
                                : { paddingLeft: '1rem' }
                        ]"
                    >
                        <v-btn
                            class="text-none font-weight-light"
                            outlined
                            height="fit-content"
                            style="
                                justify-content: flex-start;
                                border-width: 0 !important;
                                border-color: #3181b6;
                                font-size: 0.9rem;
                                padding: 0.2em;
                                margin: 0.8em;
                                min-width: 0;
                            "
                            :ripple="false"
                            v-if="sideBarInstance && isMobile"
                            :style="[
                                newSessionBlocked
                                    ? { color: '#999999 !important' }
                                    : { color: '#ffffff !important' }
                            ]"
                            :disabled="newSessionBlocked"
                            @click="openNewConversation"
                        >
                            <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    viewBox="0 0 100 115"
                                    xml:space="preserve"
                                    style="width: 28px; height: 28px;"
                                >
                                    <path
                                        d="M10,58.1v9.3c0,1.1,0.5,2.1,1.2,2.9c0.7,0.7,1.7,1.1,2.7,1.1c1,0,2-0.4,2.8-1.2l11.8-11.8h35.7c5.8,0,10.4-4.7,10.4-10.4V16.4c0-5.8-4.7-10.4-10.4-10.4h-52c-5.8,0-10.4,4.7-10.4,10.4v31.5C1.7,52.9,5.3,57.1,10,58.1z M25.8,28.6h9l0-9h6.9l0,9l9,0v6.9l-9,0v9h-6.9v-9h-9V28.6z M98.3,39.1v31.5c0,5-3.6,9.2-8.3,10.2v9.3c0,2.2-1.8,3.9-3.9,3.9c-1,0-2-0.4-2.8-1.1L71.5,81.1H35.8c-5.6,0-10.1-4.4-10.4-9.8l6-6h1.2v5.4c0,1.7,1.4,3.2,3.2,3.2h38.7l8.2,8.2v-8.2h5.2c1.7,0,3.2-1.4,3.2-3.2V39.1c0-1.7-1.4-3.2-3.2-3.2h-6.3v-7.3h6.3C93.6,28.7,98.3,33.4,98.3,39.1z"
                                        :fill="newSessionBlocked?'#999999': 'rgb(220, 220, 220)'"
                                    />
                                </svg>
                        </v-btn>
                    </div>
                    <v-container
                        class="mx-0"
                        :class="isMobile ? 'px-0' : ''"
                        id="chatLog"
                        @scroll.passive="chatScrolled"
                        :style="[
                            isMobile
                                ? {
                                      overflowX: 'hidden',
                                      padding: '0 1rem 0 1rem'
                                  }
                                : { paddingTop: '0%', paddingBottom: '0' },
                            noTransition
                                ? {
                                      scrollBehavior: 'auto !important'
                                  }
                                : {
                                      scrollBehavior: 'smooth !important'
                                  }
                        ]"
                    >
                        <transition-group
                            name="slide-up"
                            @before-enter="scrollToBottom"
                            style="overscroll-behavior: contain"
                            :key="messagesKey"
                        >
                            <div
                                :key="msg.id"
                                v-for="(msg, index) in messages"
                                style="
                                    display: flex;
                                    justify-content: center;
                                    flex-wrap: wrap;
                                "
                                :style="
                                    isMobile
                                        ? { padding: '0 0 0 0.6rem' }
                                        : { padding: '0 0.8rem 0 0.8rem' }
                                "
                            >
                                <v-card
                                    class="message my-2 mr-0 d-flex align-center"
                                    :class="noTransition ? 'notransition' : ''"
                                    flat
                                    :width="isMobile ? '80%' : '72%'"
                                    :color="msg.isQuery ? '#303850' : '#4C5672'"
                                    v-if="msg.message_type == 'text'"
                                    :id="'msgCard' + msg.id"
                                >
                                    <div
                                        class="sender-icon"
                                        :style="
                                            isMobile
                                                ? {
                                                      height: '2rem',
                                                      width: '2rem',
                                                      left: '-40px',
                                                      top: '4px'
                                                  }
                                                : {
                                                      height: '2.5rem',
                                                      width: '2.5rem',
                                                      left: '-58px',
                                                      top: '6px'
                                                  }
                                        "
                                    >
                                        <LogoSvgComponent
                                            v-if="!msg.isQuery"
                                            width="1.5em"
                                            height="1.5em"
                                            :style="{
                                                transform: isMobile
                                                    ? 'scale(0.7)'
                                                    : ''
                                            }"
                                        />
                                        <v-avatar
                                            v-if="msg.isQuery"
                                            color="transparent"
                                            size="28"
                                            tile
                                            style="
                                                flex: 0 0 auto !important;
                                                color: white;
                                            "
                                            :style="{
                                                transform: isMobile
                                                    ? 'scale(0.7)'
                                                    : ''
                                            }"
                                        >
                                            <v-icon color="white" stroke="2px"
                                                >mdi-account-outline</v-icon
                                            >
                                        </v-avatar>
                                    </div>
                                    <v-card-text
                                        :style="
                                            isMobile
                                                ? {
                                                        padding:'12px 12px 12px 12px',
                                                        overflowX: 'hidden'
                                                    }
                                                : {
                                                        paddingLeft: '26px',
                                                        paddingRight: '42px',
                                                        overflowX: 'hidden',
                                                        fontSize: '16px'
                                                    }
                                        "
                                        >
                                        <markdown-renderer :markdown-content="msg.content.text"></markdown-renderer>
                                    </v-card-text>
                                    <div v-if="!msg.isQuery">
                                        <v-card-actions
                                            style="
                                                position: absolute;
                                                top: 2px;
                                                padding: 0px;
                                            "
                                            :style="
                                                isMobile
                                                    ? { right: '10px' }
                                                    : { right: '6px' }
                                            "
                                        >
                                            <v-btn
                                                v-if="!isMobile"
                                                icon
                                                color="#32C3FD"
                                                @click="
                                                    copyMessage(
                                                        msg.content.text
                                                    )
                                                "
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 32 32"
                                                >
                                                    <path
                                                        fill="currentColor"
                                                        d="M28 10v18H10V10h18m0-2H10a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z"
                                                    />
                                                    <path
                                                        fill="currentColor"
                                                        d="M4 18H2V4a2 2 0 0 1 2-2h14v2H4Z"
                                                    />
                                                </svg>
                                            </v-btn>
                                            <div
                                                v-if="!msg.isQuery"
                                                :class="
                                                    msg.sentiment == null
                                                        ? tu_td_double_style
                                                        : tu_td_single_style
                                                "
                                            >
                                                <!-- <v-btn
                                                    v-if="isMobile"
                                                    icon
                                                    color="#32C3FD"
                                                    @click="
                                                        copyMessage(
                                                            msg.content.text
                                                        )
                                                    "
                                                    style="margin-bottom: -8px"
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 32 32"
                                                    >
                                                        <path
                                                            fill="currentColor"
                                                            d="M28 10v18H10V10h18m0-2H10a2 2 0 0 0-2 2v18a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2V10a2 2 0 0 0-2-2Z"
                                                        />
                                                        <path
                                                            fill="currentColor"
                                                            d="M4 18H2V4a2 2 0 0 1 2-2h14v2H4Z"
                                                        />
                                                    </svg>
                                                </v-btn> -->

                                                <v-btn
                                                    icon
                                                    style="margin-bottom: -8px"
                                                    color="#1DD3C0"
                                                    @click="
                                                        open_feedback_popup(
                                                            index,
                                                            'thumbs_up'
                                                        )
                                                    "
                                                    v-if="
                                                        msg.sentiment == null ||
                                                        msg.sentiment ==
                                                            'thumbs_up'
                                                    "
                                                >
                                                    <v-icon
                                                        dark
                                                        size="18px"
                                                        v-if="
                                                            msg.sentiment ===
                                                            'thumbs_up'
                                                        "
                                                    >
                                                        mdi-thumb-up
                                                    </v-icon>
                                                    <v-icon
                                                        dark
                                                        size="18px"
                                                        v-else
                                                    >
                                                        mdi-thumb-up-outline
                                                    </v-icon>
                                                </v-btn>

                                                <v-btn
                                                    icon
                                                    color="#FD7C7C"
                                                    @click="
                                                        open_feedback_popup(
                                                            index,
                                                            'thumbs_down'
                                                        )
                                                    "
                                                    v-if="
                                                        msg.sentiment == null ||
                                                        msg.sentiment ==
                                                            'thumbs_down'
                                                    "
                                                >
                                                    <v-icon
                                                        dark
                                                        size="18px"
                                                        v-if="
                                                            msg.sentiment ===
                                                            'thumbs_down'
                                                        "
                                                    >
                                                        mdi-thumb-down
                                                    </v-icon>
                                                    <v-icon
                                                        dark
                                                        size="18px"
                                                        v-else
                                                    >
                                                        mdi-thumb-down-outline
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </v-card-actions>
                                    </div>
                                </v-card>
                                <div
                                    style="
                                        height: 300px;
                                        display: block;
                                        justify-content: center;
                                        margin-bottom: 0.8rem;
                                    "
                                    :style="{ width: isMobile ? '80%' : '72%' }"
                                    v-if="msg.message_type == 'graph'"
                                >
                                    <HistPriceGraph
                                        :series="msg.content.series"
                                        :name="msg.content.name"
                                    />
                                </div>
                                <div
                                    v-if="msg.message_type == 'vis_event'"
                                    :style="{
                                        width: isMobile ? '80%' : '72%',
                                        marginBottom: '0.4rem'
                                    }"
                                >
                                    <GMapPricer
                                        v-if="msg.vis_type == 'pricer_polyline'"
                                        :mapEvents="msg.event"
                                        :isMobile="isMobile"
                                        :id="'pricer_polyline' + index"
                                    />
                                    <GMapPolyline
                                        v-if="msg.vis_type == 'gmaps'"
                                        :mapEvents="msg.event"
                                        :markerData="msg.marker_data"
                                        :transit_markers="msg.transit_markers"
                                        :isMobile="isMobile"
                                        :id="'gmap_' + index"
                                    />
                                    <WindyMap
                                        v-else-if="msg.vis_type == 'windy'"
                                        :windyEvents="msg.event"
                                        :id="'windymap_' + index"
                                        :isMobile="isMobile"
                                    />

                                    <GMapPolygon
                                        v-else-if="msg.vis_type == 'polygon'"
                                        :polygonEvents="msg.event"
                                        :isMobile="isMobile"
                                        :id="'gmap_' + index"
                                    />
                                </div>
                                <div
                                    v-if="
                                        msg.message_type ==
                                        'carrier_search_results'
                                    "
                                    :style="{
                                        width: isMobile ? '80%' : '72%',
                                        marginBottom: '0.4rem'
                                    }"
                                >
                                    <carrierSearch
                                        :carrier_data="msg.event"
                                        :isMobile="isMobile"
                                        :id="'carrier_' + index"
                                        :websocket="lang_chain_socket"
                                        :is_public="false"
                                    />
                                </div>
                                <div
                                    :style="
                                        isMobile
                                            ? {
                                                  width: '100%',
                                                  paddingLeft: '11%'
                                              }
                                            : {
                                                  width: '72%',
                                                  paddingLeft: '8px'
                                              }
                                    "
                                    style="
                                        height: 20px;
                                        margin-top: -0.2rem;
                                        margin-bottom: -1rem;
                                    "
                                    v-if="index === messages.length - 1"
                                >
                                    <div
                                        :key="eventcounter"
                                        v-if="msgLoading"
                                        class="d-flex justify-start loadingPhrase"
                                        :style="
                                            isMobile
                                                ? { position: 'inherit' }
                                                : {
                                                      width: '100%'
                                                  }
                                        "
                                    >
                                        <vue-typed-js
                                            :key="loadingPhraseKey"
                                            :strings="loadingPhrases"
                                            :showCursor="false"
                                            :contentType="'html'"
                                            :backDelay="2500"
                                            :typeSpeed="10"
                                        >
                                            <span
                                                class="typing loading"
                                                style="
                                                    white-space: pre-wrap;
                                                    word-break: keep-all;
                                                    font-size: 0.8rem;
                                                "
                                            >
                                            </span>
                                        </vue-typed-js>
                                    </div>
                                </div>
                            </div>
                            <div
                                key="scrollHelper"
                                id="scrollHelper"
                                style="height: 1px"
                            />
                        </transition-group>
                    </v-container>
                    <div
                        v-if="
                            followupPrompts.length !== 0 &&
                            showFollowups &&
                            !isMobile &&
                            !errorMessage
                        "
                        key="followupPrompts"
                        style="
                            padding-left: 0px;
                            padding-bottom: 16px;
                            justify-content: center;
                            display: flex;
                            wrap: wrap;
                            align-items: stretch;
                            max-width: 72%;
                        "
                        :style="
                            isMobile
                                ? { flexDirection: 'column', maxWidth: '100%' }
                                : {}
                        "
                    >
                        <div
                            class="promptExample fade-in"
                            v-for="prompt in followupPrompts"
                            :key="prompt"
                            @click="promptClicked(prompt)"
                            :style="isMobile ? { marginTop: '.8rem' } : {}"
                        >
                            {{ prompt }}
                        </div>
                    </div>
                    <div style="
                            display: flex;
                            justify-content: center;
                            flex-wrap: wrap;
                        "
                        v-if="conversation_token_warning"
                        id="conversation_token_warning"
                    >
                        <v-card
                            class="message-token-warning my-2 d-flex align-center fade-in-fast"
                            :class="noTransition ? 'notransition' : ''"
                            flat
                            :width="isMobile ? '80%' : '100%'"
                        >
                        <v-card-text class="d-flex pt-1 pb-1 align-center" style="color: white; font-size: 16px;">
                            <v-icon left>mdi-alert</v-icon>
                                <span class="font-weight-bold ml-2">We've noticed your chat is growing quite long! To ensure precise results, please start a new conversation when searching for new lanes or carriers.</span>
                            <v-spacer />
                            <v-btn icon class="ml-3">
                                <v-icon
                                x-large
                                color="white"
                                class="text-none font-weight-bold"
                                style="
                                    font-size: 1.6rem;
                                    background: transparent;
                                    position: absolute;
                                "
                                @click="clearTokenWarning(true)"
                                >mdi-close</v-icon
                            >
                            </v-btn>
                        </v-card-text>
                        </v-card>
                    </div>
                    <!-- <div
                        v-if="isNewConv && !errorMessage"
                        style="
                            padding-left: 0px;
                            padding-bottom: 16px;
                            justify-content: center;
                            display: flex;
                            wrap: wrap;
                            align-items: stretch;
                        "
                        :style="
                            isMobile
                                ? {
                                      flexDirection: 'column',
                                      width: '100%',
                                      paddingLeft: '0.4rem',
                                      paddingRight: '0.4rem'
                                  }
                                : { width: '72%' }
                        "
                    >
                        <div
                            class="promptExample"
                            v-for="prompt in examplePrompts"
                            :key="prompt"
                            @click="promptClicked(prompt)"
                            :style="isMobile ? { marginTop: '.8rem' } : {}"
                        >
                            {{ prompt }}
                        </div>
                    </div> -->
                    <div
                        v-if="errorMessage"
                        style="
                            display: flex;
                            justify-content: space-between;
                            position: relative;
                            top: 25px;
                        "
                    >
                        <v-alert
                            icon="mdi-alert-circle-outline"
                            dense
                            outlined
                            type="error"
                        >
                            {{ errorMessage }}
                        </v-alert>
                        <span style="padding: 0px 20px">
                            <v-btn
                                @click="openNewRequest"
                                style="cursor: pointer"
                                class="mt-1"
                                :ripple="false"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    viewBox="0 0 100 105"
                                    xml:space="preserve"
                                    style="width: 28px; height: 28px;"
                                >
                                    <path
                                        d="M10,58.1v9.3c0,1.1,0.5,2.1,1.2,2.9c0.7,0.7,1.7,1.1,2.7,1.1c1,0,2-0.4,2.8-1.2l11.8-11.8h35.7c5.8,0,10.4-4.7,10.4-10.4V16.4c0-5.8-4.7-10.4-10.4-10.4h-52c-5.8,0-10.4,4.7-10.4,10.4v31.5C1.7,52.9,5.3,57.1,10,58.1z M25.8,28.6h9l0-9h6.9l0,9l9,0v6.9l-9,0v9h-6.9v-9h-9V28.6z M98.3,39.1v31.5c0,5-3.6,9.2-8.3,10.2v9.3c0,2.2-1.8,3.9-3.9,3.9c-1,0-2-0.4-2.8-1.1L71.5,81.1H35.8c-5.6,0-10.1-4.4-10.4-9.8l6-6h1.2v5.4c0,1.7,1.4,3.2,3.2,3.2h38.7l8.2,8.2v-8.2h5.2c1.7,0,3.2-1.4,3.2-3.2V39.1c0-1.7-1.4-3.2-3.2-3.2h-6.3v-7.3h6.3C93.6,28.7,98.3,33.4,98.3,39.1z"
                                        :fill="'rgb(220, 220, 220)'"
                                    />
                                </svg>
                            </v-btn>
                        </span>
                    </div>
                    <v-textarea
                        v-else
                        ref="textField"
                        v-model="text"
                        class="textField highlight"
                        :class="[
                            active ? 'active' : '',
                            isMobile ? 'mobile' : ''
                        ]"
                        rows="1"
                        filled
                        hide-details
                        solo
                        flat
                        auto-grow
                        background-color="rgba(120, 120, 120, 0.2)"
                        @keydown="handleKeyboard"
                        @focus="handleInputFocus"
                        @blur="handleInputBlur"
                    >
                        <template #append>
                            <v-icon
                                v-on="submittable ? { click: submit } : {}"
                                :class="{ clickable: submittable }"
                                class="mt-1 ml-2"
                                color="#656565"
                                ref="sendBtn"
                                id="sendBtn"
                                >mdi-send</v-icon
                            >
                        </template>
                        <template #prepend v-if="sideBarInstance && !isMobile">
                            <v-btn
                                :ripple="false"
                                :style="[
                                    newSessionBlocked
                                        ? { color: '#999999 !important' }
                                        : { color: 'rgb(220, 220, 220) !important' },
                                    highlightIcon
                                        ? { left: '-3.95rem', top: '0.4rem' }
                                        : { left: '-3.34rem', top: '1rem' },
                                ]"
                                class="text-none font-weight-light"
                                :class="{ 'warning-border': highlightIcon }"
                                style="
                                    justify-content: flex-start;
                                    background: transparent;
                                    position: absolute;
                                    background-color: transparent;
                                    "
                                    :disabled="newSessionBlocked"
                                @click="openNewConversation"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    version="1.1"
                                    viewBox="0 0 100 115"
                                    xml:space="preserve"
                                    style="width: 28px; height: 28px;"
                                >
                                    <path
                                        d="M10,58.1v9.3c0,1.1,0.5,2.1,1.2,2.9c0.7,0.7,1.7,1.1,2.7,1.1c1,0,2-0.4,2.8-1.2l11.8-11.8h35.7c5.8,0,10.4-4.7,10.4-10.4V16.4c0-5.8-4.7-10.4-10.4-10.4h-52c-5.8,0-10.4,4.7-10.4,10.4v31.5C1.7,52.9,5.3,57.1,10,58.1z M25.8,28.6h9l0-9h6.9l0,9l9,0v6.9l-9,0v9h-6.9v-9h-9V28.6z M98.3,39.1v31.5c0,5-3.6,9.2-8.3,10.2v9.3c0,2.2-1.8,3.9-3.9,3.9c-1,0-2-0.4-2.8-1.1L71.5,81.1H35.8c-5.6,0-10.1-4.4-10.4-9.8l6-6h1.2v5.4c0,1.7,1.4,3.2,3.2,3.2h38.7l8.2,8.2v-8.2h5.2c1.7,0,3.2-1.4,3.2-3.2V39.1c0-1.7-1.4-3.2-3.2-3.2h-6.3v-7.3h6.3C93.6,28.7,98.3,33.4,98.3,39.1z"
                                        :fill="newSessionBlocked?'#999999': 'rgb(220, 220, 220)'"
                                    />
                                </svg>
                            </v-btn>
                        </template>
                    </v-textarea>
                </v-container>
            </v-container>
            <!-- Dialog Components -->
            <v-dialog
                v-model="feedback_popup"
                max-width="1000px"
                class="custom-dialog"
            >
                <v-card style="background-color: #201f1f; color: white">
                    <v-card-title
                        class="pt-3 pb-3"
                        style="border-bottom: 1px solid #656565"
                    >
                        <v-icon
                            left
                            v-if="feedback_type == 'thumbs_up'"
                            color="#1DD3C0"
                            >mdi-thumb-up</v-icon
                        >
                        <v-icon
                            left
                            v-if="feedback_type == 'thumbs_down'"
                            color="#FD7C7C"
                            >mdi-thumb-down</v-icon
                        >
                        <span class="ml-3">Provide Feedback</span>
                        <!-- Close Button -->
                        <v-spacer></v-spacer>
                        <v-btn icon @click="close_feedback" color="white">
                            <v-icon color="#656565">mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text class="pt-8 pb-5">
                        <!-- Textarea with auto-focus -->
                        <v-textarea
                            ref="feedback_textarea"
                            dark
                            filled
                            hide-details
                            solo
                            flat
                            auto-grow
                            no-resize
                            v-model="feedback_comment"
                            background-color="rgba(48,56,80,0.5)"
                            :placeholder="
                                feedback_type == 'thumbs_up'
                                    ? 'What do you like about this response?'
                                    : 'What can be improved with this response?'
                            "
                            class="feedback-textarea"
                        ></v-textarea>
                        <v-checkbox
                            v-if="
                                showPricingFeedback &&
                                feedback_type == 'thumbs_down'
                            "
                            v-model="feedbackOverPriced"
                            label="The price is too high"
                            dark
                            hide-details
                            dense
                        />
                        <v-checkbox
                            v-if="
                                showPricingFeedback &&
                                feedback_type == 'thumbs_down'
                            "
                            v-model="feedbackUnderPriced"
                            label="The price is too low"
                            dark
                            hide-details
                            dense
                        />
                    </v-card-text>

                    <v-card-actions class="pb-5">
                        <!-- Submit Button -->
                        <v-spacer></v-spacer>
                        <v-btn
                            outlined
                            class="feedback-button mr-2"
                            color="#F8F8F8"
                            @click="close_feedback"
                            >Submit Response</v-btn
                        >
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-main>
    </v-app>
</template>
<script lang="ts">
import Vue from 'vue';
// import L from 'leaflet';
import _ from 'lodash';
// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// import 'leaflet/dist/leaflet.css';
import LangChainSocket from '../websocket';
// import marked from 'marked';
import SideBar from '../components/SideBar.vue';
import LogoSvgComponent from '../components/LogoSVG.vue';
import { ResponseObject } from '../types/responseObject';
import { Auth } from 'aws-amplify';
import { UserInterface } from '../types/userObject';
import WindyMap from '../components/WindyMaps.vue';
import GMapPolyline from '../components/GMapPolyline.vue';
import GMapPricer from '../components/GMapPricer.vue';
import { isDevEnv, APIEndpoints } from '../resources';
import GMapPolygon from '../components/GMapPolygon.vue';
import carrierSearch from '../components/carrierSearch.vue';
import * as analytics from '../analytics/pinpoint';
import VueApexCharts from 'vue-apexcharts';
import HistPriceGraph from '@/components/HistPriceGraph.vue';
import NewConversationDiagram from '../components/NewConversationDiagram.vue';
import MarkdownRenderer from '../components/MarkdownRenderer.vue';
import '@/components/rfp_tool/rfp-shared-styles.css'
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts); // eslint-disable-line vue/multi-word-component-names

export default Vue.extend({
    name: 'ChatStream',
    props: [],

    components: {
        SideBar,
        LogoSvgComponent,
        WindyMap,
        GMapPolyline,
        GMapPricer,
        GMapPolygon,
        HistPriceGraph,
        carrierSearch,
        NewConversationDiagram,
        MarkdownRenderer
    },

    methods: {
        async fetchMaintenanceMessage(){
            try {
                const url = isDevEnv()?APIEndpoints.dev.maintenance_alert:APIEndpoints.prod.maintenance_alert
                const response = await fetch(url);
                let data = await response.json();
                data = JSON.parse(data.body)
                if (response.ok) {
                    for (const item of data.items) {
                        if (item.error_code === "500") {
                            this.showAlert = true
                            this.alert_message = item.error_message
                        }
                    }
                } else {
                    console.error('Error:', data.errorMessage);
                }
            } catch (err) {
                console.error('Request failed', err);
            }
        },
        handleExampleClick(example: any){
            this.promptClicked(example);
        },
        handleKeyboard(event: any) {
            if (event.key === 'Enter' && this.submitLocked) {
                event.preventDefault();
                return;
            }
            if (
                event.key === 'Enter' &&
                !event.shiftKey &&
                this.text.replace(/\s/g, '') !== ''
            ) {
                event.preventDefault();
                this.submit();
            }
        },
        clearTokenWarning(block: boolean = false){
            document.getElementById('conversation_token_warning')?.classList.add('fast-hide');
            this.highlightIcon = false;
            this.conversation_token_warning = false;
            this.conversation_token_warning_flag = false;

            this.conversation_token_warning_block = block;
            if(!this.isMobile){
                (this.$refs.textField as any)?.focus();
            }
        },
        submit() {
            analytics.sendEvent(
                'send_query',
                this.user_info.sub,
                this.user_info.name,
                this.user_info.email
            );
            this.transit_markers = [];
            this.noTransition = false;
            this.disableAutoScroll = false;
            if (this.submitLocked || this.text === '') {
                return;
            }
            this.active = true;
            this.isNewConv = false;
            if (this.messages.length === 0 && !this.firstMessageSent) {
                this.sideBarInstance.appendConvToHistory(
                    this.curr_conversation_id
                );
                this.firstMessageSent = true;
            }
            // add text to messages and clear field
            this.logUserQuery(this.text);
            this.followupPrompts = [];
            this.showFollowups = false;
            this.loadingPhrases = [''];
            setTimeout(() => {
                this.loadingPhrases = ['Sending request'];
                this.loadingPhraseKey += 1;
            }, 350);
            this.msgLoading = true;

            // hit API using text, then use returned value to make query
            this.submitLocked = true;
            this.streamDone = false;
            this.$nextTick(() => {
                const sendBtn = this.$refs.sendBtn as any;
                sendBtn.$el.classList.add('locked');
            });

            // Prepare the message object
            var messageObject: any = {
                action: this.chat_endpoint,
                message: this.text,
                session_id: this.curr_session_id,
                user_id: this.user_id,
                conversation_id: this.curr_conversation_id
            };
            if (this.alb_cookie_value !== 'ALB=null;') {
                messageObject['cookie'] = this.alb_cookie_value;
            }
            this.currentSocket = this.lang_chain_socket.sendMessage(
                messageObject,
                this.handleResponseToken
            );

            this.pushMessage(false, { text: '' });
            this.textField.reset();
            if (!this.isMobile) {
                this.textField?.focus();
            } else {
                this.textField.blur();
            }
        },
        openNewRequest() {
            this.text = '';
            this.errorMessage = '';
            this.resetCurConvIndValue = true;
            this.newSession(true);
            this.clearMessages();
        },
        resetEmitFunc() {
            this.resetCurConvIndValue = false;
        },
        generateRandomString(length: number) {
            const characters =
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let result = '';
            for (let i = 0; i < length; i++) {
                result += characters.charAt(
                    Math.floor(Math.random() * characters.length)
                );
            }
            return result;
        },

        pushMessage(
            isQuery: boolean,
            content: Object,
            type: string = 'text',
            message_id: any = null
        ) {
            this.messages.push({
                id: this.generateRandomString(this.msgCounter + 1),
                message_id: message_id,
                isQuery: isQuery,
                sentiment: null,
                timestamp: Date.now(),
                content: content,
                copyDisabled: true,
                message_type: type
            });
            this.msgCounter += 1;
        },

        pushVisEvent(events: any, message_id: any = null) {
            if (
                this.vis_events.length == 0 &&
                !_.isEmpty(this.transit_markers.length)
            ) {
                this.messages.push({
                    id: this.generateRandomString(this.msgCounter + 1),
                    message_id: message_id,
                    event: events,
                    vis_type: 'gmaps',
                    message_type: 'vis_event',
                    transit_markers: this.transit_markers,
                    marker_data: this.marker_data
                });
                this.msgCounter += 1;
            } else if (
                this.vis_events.length != 0 ||
                this.transit_markers.length != 0
            ) {
                this.messages.push({
                    id: this.generateRandomString(this.msgCounter + 1),
                    message_id: message_id,
                    event: events,
                    vis_type: events[0]['type'],
                    message_type: 'vis_event',
                    marker_data: this.marker_data,
                    transit_markers: this.transit_markers
                });
                this.msgCounter += 1;
            }

            this.marker_data = {};
            this.transit_markers = [];
            this.vis_events = [];
        },

        pushCarrierSearch(event: any, message_id: any = null) {
            this.messages.push({
                id: this.generateRandomString(this.msgCounter + 1),
                message_id: message_id,
                event: event,
                message_type: 'carrier_search_results'
            });
            this.msgCounter += 1;
        },

        populateExamplePrompts() {
            let pool = this.examplePromptPool;
            this.examplePrompts = [
                pool.pricer[Math.floor(pool.pricer.length * Math.random())],
                pool.weather[Math.floor(pool.weather.length * Math.random())],
                pool.carrier[Math.floor(pool.carrier.length * Math.random())]
            ];
        },

        promptClicked(prompt: string) {
            this.text = prompt;
            this.submit();
        },

        scrollToBottom() {
            if (this.disableAutoScroll) {
                return;
            }
            requestAnimationFrame(() => {
                (document.getElementById('scrollHelper') as any).scrollIntoView(
                    {
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'nearest'
                    }
                );
            });
            const container = document.getElementById('chatLog') as any;
            requestAnimationFrame(() => {
                container.scrollTop = container.scrollHeight;
            });
        },

        newSession(isNewConv: boolean = false) {
            this.clearTokenWarning();
            this.isNewConv = isNewConv;
            if (isNewConv) {
                this.errorMessage = '';
            }
            this.msgLoading = false;
            this.populateExamplePrompts();
            this.sideBarLoading = true;
            if (!this.streamDone) {
                this.text = '';
                this.streamDone = true;
                this.submitLocked = false;
            }
            Auth.currentAuthenticatedUser().then((user) => {
                this.user_id = user.attributes.sub;

                this.user_info = user.attributes;

                this.scrollToBottom();
                // Prepare the message object
                var messageObject: any = {
                    action: this.new_session_endpoint,
                    user_id: user.attributes.sub,
                    session_id: this.curr_session_id,
                    conversation_id: this.isNewConv
                        ? null
                        : this.curr_conversation_id,
                    tags: [this.isMobile ? 'mobile' : 'desktop']
                };
                if (this.alb_cookie_value !== 'ALB=null;') {
                    messageObject['cookie'] = this.alb_cookie_value;
                }
                this.sideBarLoading = false;
                this.lang_chain_socket.sendMessage(
                    messageObject,
                    this.setNewSessionProperties
                );
            });
        },

        setNewSessionProperties(event: any, socket: any) {
            let response_dict = JSON.parse(event.data);
            if (response_dict['event']['status'] == 'SUCCESS') {
                this.curr_session_id = response_dict['event']['session_id'];
                this.curr_conversation_id =
                    response_dict['event']['conversation_id'];
                if (!this.isMobile && this.$refs.textField) {
                    (this.$refs.textField as any)?.focus();
                }

                if (
                    'cookie' in response_dict['event'] &&
                    this.alb_cookie_value == 'ALB=null;'
                ) {
                    document.cookie = response_dict['event']['cookie'];
                    this.alb_cookie_value = `ALB=${this.getCookieValue(
                        'ALB'
                    )};`;
                }
            } else {
                console.log('New session failed.', event);
            }
            this.sideBarLoading = false;
            socket.close();
        },

        setRefInstance(component: any) {
            this.sideBarInstance = component;
        },

        closeSessionDummyCallback(event: any, socket: any) {
            console.log(event, socket);
        },

        closeSessionCallback(event: any, socket: any) {
            socket.close();
        },

        handleResponseToken(event: any, socket: any) {
            if (this.messages.length === 0) {
                this.submitLocked = false;
                return;
            }
            if (this.streamDone) {
                socket.close();
                this.text = '';
                this.submitLocked = false;
                return;
            }
            let response_dict = JSON.parse(event.data);
            let keys = Object.keys(response_dict);
            if (keys.includes('token')) {
                let responseToken = response_dict['token'];
                this.messages[this.lastResponseIndex]['content']['text'] +=
                    responseToken;
                this.msgLoading = false;
                let lastMsgId = this.messages[this.lastResponseIndex].id;
                let newMsgHeight = document.getElementById(
                    'msgCard' + lastMsgId
                )?.offsetHeight;
                if (this.prevMsgHeight !== newMsgHeight) {
                    this.scrollToBottom();
                }
                this.prevMsgHeight = newMsgHeight;
            } else if (keys.includes('event')) {
                var event_content = response_dict['event'];
                switch (event_content.type) {
                    case 'run_id':
                        this.curr_parent_run_id = event_content.data.run_id;
                        this.messages[this.msgCounter - 1].message_id =
                            this.curr_parent_run_id;
                        this.messages[this.msgCounter - 2].message_id =
                            this.curr_parent_run_id;
                        break;
                    case 'loading_description':
                        this.loadingPhrases = [event_content.data];
                        this.loadingPhraseKey += 1;
                        this.scrollToBottom();
                        this.loadText = event_content.data;
                        if (this.loadText === 'Predicting truck cost') {
                            this.messages[this.messages.length - 1].isPricing =
                                true;
                        }
                        break;
                    case 'graph_series':
                        this.graph_data.push(event_content.data);
                        break;
                    case 'pricer_polyline':
                    case 'gmaps':
                    case 'windy':
                    case 'polygon':
                        this.addToVisEvents(event_content);
                        break;
                    case 'transit_markers':
                        this.transit_markers.push(event_content.data);
                        break;
                    case 'carrier_search_results':
                        this.carrierSearchResults = event_content.data;
                        break;
                    case 'followup_suggestions':
                        this.followupPrompts = event_content.data;
                        this.showFollowups = false;
                        break;
                    case 'summary':
                        this.summaryShow = event_content['data'];
                        break;
                    case 'error':
                        this.errorMessage = event_content.data;
                        break;
                    case 'conversation_token_warning':
                        this.conversation_token_warning_flag = true;
                        break;
                }
            } else if (keys.includes('close')) {
                socket.close();
                this.submitLocked = false;
                this.messages[this.messages.length - 1].copyDisabled = false;
                let delay_convo_warning = this.vis_events.length > 0 || this.graph_data.length > 0 || this.carrierSearchResults;
                if (this.carrierSearchResults) {
                    this.pushCarrierSearch(this.carrierSearchResults);
                    this.carrierSearchResults = null;
                }
                this.pushVisEvent(this.vis_events, this.curr_parent_run_id);
                if (this.graph_data.length > 0) {
                    for (const entry of this.graph_data) {
                        this.attachGraph(entry);
                    }
                    this.graph_data = [];
                }
                setTimeout(() => {
                    const sendBtn = this.$refs.sendBtn as any;
                    if (sendBtn) {
                        sendBtn.$el.classList.remove('locked');
                    }
                }, 0);
                if (this.conversation_token_warning_flag && !this.conversation_token_warning_block){
                    setTimeout(() => {
                        this.highlightIcon = true;
                        this.conversation_token_warning = true;
                    }, delay_convo_warning ? 1000 : 0 );
                } else {
                    this.showFollowups = true;
                }

                this.streamDone = true;
                this.curr_parent_run_id = '';
            }
        },

        logUserQuery(queryText: string) {
            this.pushMessage(true, { text: queryText });
        },

        populateTemplate(template: any, parameters: Object) {
            // Not in use, but just in case I need to do templating of some kind
            for (const [key, value] of Object.entries(parameters)) {
                template = template.replace(
                    new RegExp(`{\\s*${key}\\s*}`, 'gi'),
                    value
                );
            }
            return template;
        },

        translateResponse(responseObj: ResponseObject) {
            let origin =
                responseObj.origin_city + ', ' + responseObj.origin_state;
            let destination =
                responseObj.destination_city +
                ', ' +
                responseObj.destination_state;
            return {
                origin: origin,
                destination: destination,
                date: responseObj.origin_close_day,
                equipmentType: 'Reefer',
                price: parseFloat(responseObj.mlPrice).toFixed(2)
            };
        },

        open_feedback_popup(msg_index: number, type: string) {
            this.feedback_popup = true;
            this.feedback_type = type;
            this.feedback_index = msg_index;
            this.showPricingFeedback =
                !!this.messages[this.feedback_index].isPricing;
            this.$nextTick(() => {
                const textarea = this.$refs.feedback_textarea as any;
                textarea?.focus();
            });
            this.messages[this.feedback_index].sentiment = this.feedback_type;
        },

        close_feedback() {
            this.feedback_popup = false;
        },

        submit_feedback() {
            analytics.sendEvent(
                'feedback_' + this.feedback_type,
                this.user_info.sub,
                this.user_info.name,
                this.user_info.email
            );
            var run_id = this.messages[this.feedback_index].message_id.replace(
                /%\d/,
                ''
            );
            // Prepare the message object
            var messageObject: any = {
                action: this.feedback_endpoint,
                feedback_type: this.feedback_type,
                run_id: run_id,
                comment: this.feedback_comment,
                user_id: this.user_id,
                conversation_id: this.curr_conversation_id,
                query: this.messages[this.feedback_index - 1].content.text,
                response: this.messages[this.feedback_index].content.text,
                flags: [
                    ...(this.feedbackOverPriced ? ['overPriced'] : []),
                    ...(this.feedbackUnderPriced ? ['underPriced'] : [])
                ]
            };
            if (this.alb_cookie_value !== 'ALB=null;') {
                messageObject['cookie'] = this.alb_cookie_value;
            }
            this.lang_chain_socket.sendMessage(
                messageObject,
                this.closeSessionCallback
            );
            this.feedback_comment = '';
        },

        timeAgo(timestamp: number) {
            return timestamp - Date.now();
        },

        copyMessage(message: string) {
            navigator.clipboard.writeText(message);
            document.getElementById('copiedMessage')?.classList.add('show');
            setTimeout(function () {
                document
                    .getElementById('copiedMessage')
                    ?.classList.remove('show');
            }, 2000);
        },

        addToVisEvents(event: any) {
            if (this.vis_events.length == 0) {
                this.vis_events.push(event);
                return;
            }

            var last_event = this.vis_events.length - 1;
            if (event.type == this.vis_events[last_event].type) {
                this.vis_events.push(event);
            }
        },

        loadConversation(data: Array<any>, conv_id: any) {
            this.clearTokenWarning();
            this.errorMessage = '';
            this.transit_markers = [];
            if (this.currentSocket !== null) {
                this.currentSocket.close();
                this.submitLocked = false;
            }
            this.messages.splice(0);
            this.msgCounter = 0;
            this.curr_conversation_id = conv_id;
            this.followupPrompts = [];
            var last_message_id = '';
            this.noTransition = true;
            for (const message of data) {
                if (message == null) {
                    continue;
                }
                let data = message.event_data?.data
                if (message.type === 'vis_event') {
                    this.addToVisEvents(message.event_data);
                } else if (message.type == 'markers') {
                    if (message.event_data.type == 'transit_markers') {
                        this.transit_markers.push(data);
                    } else {
                        this.addToVisEvents(message.event_data);
                        this.marker_data = data;
                    }
                } else if (message.type == 'carrier_search_results') {
                    this.pushCarrierSearch(data);
                } else if (message.type == 'error') {
                    this.errorMessage = data;
                } else if (message.type === 'graph_series') {
                    this.attachGraph(data);
                } else {
                    if (message.type === 'human')
                        this.pushVisEvent(this.vis_events, message.id);
                    this.pushMessage(
                        message.type === 'human',
                        {
                            text: message.message
                        },
                        undefined,
                        message.id
                    );
                    last_message_id = message.id;
                }
            }
            this.msgCounter += 1;
            this.pushVisEvent(this.vis_events, last_message_id);
            this.newSession();
        },

        setLoadingSplash(value: boolean) {
            this.loadingSplash = value;
        },
        updateCollapseValue(value: boolean) {
            this.collapsed = value;
        },

        chatScrolled() {
            const messageContainer = document.getElementById('chatLog') as any;
            const tolerance = 50;
            this.disableAutoScroll =
                messageContainer.scrollTop + tolerance <
                messageContainer.scrollHeight - messageContainer.clientHeight;
        },

        clearMessages() {
            this.messages.splice(0);
            this.msgCounter = 0;
            this.firstMessageSent = false;
            this.followupPrompts = [];
            this.showFollowups = false;
            this.messagesKey += 1;
        },

        roundTimeseries(data: any) {
            let cleaned = [];
            for (const item of data) {
                cleaned.push([(item[0] | 0) * 1000, item[1].toFixed(2)]);
            }
            return cleaned;
        },

        attachGraph(data: any) {
            this.pushMessage(
                false,
                {
                    name: data.origin_city + '↔' + data.destination_city,
                    series: [
                        {
                            data: this.roundTimeseries(data.time_series),
                            name:
                                data.origin_city + '↔' + data.destination_city
                        }
                    ]
                },
                'graph'
            );
        },

        getCookieValue(cookieName: string) {
            var allCookies = document.cookie.split(';');
            for (var i = 0; i < allCookies.length; i++) {
                var cookie = allCookies[i].trim();
                if (cookie.startsWith(cookieName + '=')) {
                    return cookie.substring(cookieName.length + 1);
                }
            }
            return null;
        },
        handleInputBlur() {
            const textField = this.$refs.textField as any;
            textField.$el.classList.remove('highlight');
        },

        handleInputFocus() {
            this.$nextTick(() => {
                const textField = this.$refs.textField as any;
                textField.$el.classList.add('highlight');
            });
        },

        openNewConversation() {
            this.clearTokenWarning();
            if (this.sideBarInstance !== null) {
                this.sideBarInstance.newConversation();
            }
        }
    },

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        },
        submittable() {
            if (!this.submitLocked && this.text !== '' && this.text !== null) {
                return true;
            } else {
                return false;
            }
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        textCounter() {
            const textLength: string = this.text.length.toString();
            return textLength + '/1024';
        },
        isUserLoaded() {
            if (Object.keys(this.user_info).length !== 0) {
                return true;
            }
            return false;
        },
        lastResponseIndex() {
            for (let i = (this as any).messages.length - 1; i >= 0; i--) {
                if (this.messages[i].message_type === 'text') return i;
            }
            return 1;
        }
    },

    mounted() {
        this.text = '';
        this.lang_chain_socket = new LangChainSocket();
        this.fetchMaintenanceMessage()
        this.newSession(true);

        this.textField = this.$refs.textField as any;
        if (!this.isMobile) {
            this.textField?.focus();
        }

        this.populateExamplePrompts();

        // Set ALB Cookie
        this.alb_cookie_value = `ALB=${this.getCookieValue('ALB')};`;

    },

    watch: {
        feedback_popup(newVal, oldVal) {
            if (newVal === false && oldVal === true) {
                this.submit_feedback();
            }
        },
    },

    data: function () {
        return {
            showAlert: false,
            alert_message:'',
            transit_markers: [] as any,
            collapsed: false,
            textField: null as any,
            prevMsgHeight: 0 as number | undefined,
            new_session_endpoint: isDevEnv()
                ? APIEndpoints.dev.new_session_endpoint
                : APIEndpoints.prod.new_session_endpoint,
            chat_endpoint: isDevEnv()
                ? APIEndpoints.dev.chat_endpoint
                : APIEndpoints.prod.chat_endpoint,
            feedback_endpoint: isDevEnv()
                ? APIEndpoints.dev.feedback_endpoint
                : APIEndpoints.prod.feedback_endpoint,
            currentSocket: null as any,
            active: true,
            newSessionBlocked: false,
            text: '' as string,
            touchstartX: 0,
            touchendX: 0,
            submitLocked: false,
            msgLoading: false,
            sideBarLoading: false,
            eventcounter: 0,
            loadingPhrases: [''] as any[],
            loadingPhraseKey: 0,
            examplePrompts: [
                'What is the price of a Dry Van from NYC to Chicago?',
                'What is the Dry Van price from SF to Seattle with a 10% margin?',
                'What is the weather like in Miami and Chicago right now?'
            ],
            examplePromptPool: {
                pricer: [
                    'Dry Van rate from NYC to Chicago?',
                    'What is the CPM of a Dry Van from Chicago, IL to St. Louis, MO?',
                    'Atlanta to Chattanooga Dry Van rate with a 15% margin.',
                    'Dry Van rates Kenosha to Rockford, IL and NYC to Fort Wayne, IN.',
                    'Dry Van rate for Huntley, IL to Huntington, IN',
                    'Reefer rate for Rockford, IL to Joliet, IL',
                    '90 day historical rates for Chicago to Miami.',
                    '30 day historical rates for Chattanooga to Austin, TX.'
                ],
                weather: [
                    'What is weather en route from Grand Rapids, MI to Springfield, IL?',
                    'Huntington, IN to Ann Arbor, MI weather en route.',
                    'What is the weather in Fruitland, ID?',
                    'Transit time from Denver to New York.',
                    'Transit time from Dallas, TX to Miami, FL with 4 hours left of drive time.',
                    'Tell me about MC579500.',
                    'Can I see inspection data for MC1047928?',
                    'Can I see crash data for MC579500?',
                    'Is phone number 412-747-8482 associated with a carrier?',
                    'Is email info@ninikinc.com associated with a carrier?',
                ],
                carrier: [
                    'Chicago to Dallas dry van carriers',
                    'Find me LA to Phoenix reefer carriers',
                    'Find New York to Boston Reefer carriers with a max fleet size of 60',
                    'Search for carriers near Chicago that haul meat with a max fleet size of 15.',
                    'Find me carriers with flatbeds that haul Nashville, TN to Detroit, MI ',
                    'Dry van carriers near San Antonio, TX that go to Nashville, TN'
                ]
            },
            followupPrompts: [],
            showFollowups: false,
            messages: [] as any[],
            messagesKey: '',
            isCopyDisabled: true,
            awaitingResponse: false,
            loadText: 'Calculating',
            errorMessage: '',
            msgCounter: 0,
            lang_chain_socket: null as unknown as LangChainSocket,
            user_id: null,
            user_info: {} as UserInterface,
            curr_session_id: null,
            curr_conversation_id: null,
            sideBarInstance: null as any,
            loadingSplash: false,
            isNewConv: true,
            firstMessageSent: false,
            noTransition: false,
            streamDone: true,
            vis_events: [] as any[],
            marker_data: {} as any,
            carrierSearchResults: null as any,
            curr_parent_run_id: '',
            tu_td_single_style: 'd-flex flex-column tu-td-single',
            tu_td_double_style: 'd-flex flex-column tu-td-double',
            feedback_popup: false,
            feedback_comment: '',
            feedback_type: null as any,
            feedback_index: null as any,
            showPricingFeedback: false,
            feedbackUnderPriced: false,
            feedbackOverPriced: false,
            disableAutoScroll: false,
            summaryShow: null as any,
            resetCurConvIndValue: false,
            alb_cookie_value: null as any,
            graph_data: [] as any,
            conversation_token_warning_flag: false,
            conversation_token_warning: true,
            highlightIcon: true,
            conversation_token_warning_block: false
        };
    }
});
</script>
<style scoped>
::v-deep .v-main__wrap {
    display: flex;
    justify-content: center;
}

#pageContainer {
    border-radius: 20px;
    background: #23252a;
    top: 3%;
    height: 94%;
    z-index: 4;
    position: relative;
    width: calc(100% - 3rem);
    margin-right: 0.6rem;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 32px;
}

#pageContainer.mobile {
    border-radius: 0;
    position: fixed;
    top: auto;
    bottom: 0;
    height: 100%;
    z-index: 4;
    width: 100%;
    margin-right: 0;
    padding-bottom: 12px;
    padding-top: 0px;
}

.textField.active {
    top: 0;
    transition-duration: 0.8s;
    transition-property: top;
}
.textField {
    height: fit-content;
    overflow: visible;
    flex-grow: 0;
    border-radius: 8px;
    position: relative;
    width: 70%;
    border-style: solid;
    border-width: 1.4px;
    border-color: #656565;
}
.textField.mobile {
    width: calc(100% - 1.6rem);
    position: fixed;
    bottom: 0;
    z-index: 5;
    top: auto;
    margin-bottom: 0.6rem;
}
.textField.highlight {
    border-color: rgb(160, 160, 160);
}
.textField.highlight .mdi-send:not(.locked) {
    color: rgb(160, 160, 160) !important;
}
.textField ::v-deep .v-input__slot {
    border-radius: 8px;
    background-color: #23252a !important;
}
.textField ::v-deep textarea {
    align-self: center;
    max-height: 300px;
    margin-top: 0.6em !important;
    margin-bottom: 0.6em !important;
    line-height: 2rem;
    color: rgb(228, 228, 228) !important;
    caret-color: rgb(228, 228, 228);
    overflow-y: auto;
}
.textField.mobile ::v-deep textarea {
    max-height: 224px;
    margin-top: 0.2em !important;
    margin-bottom: 0.2em !important;
    align-self: center;
}
.textField::-webkit-scrollbar-corner {
    display: none;
}
.textField ::v-deep textarea::-webkit-scrollbar {
    width: 0.5rem !important;
}
.textField ::v-deep textarea::-webkit-scrollbar-track {
    background: #434141;
    border-radius: 0.815rem;
}
.textField ::v-deep textarea::-webkit-scrollbar-thumb {
    border-radius: 0.815rem;
    background-color: #656565;
}

#logContainer {
    width: 100%;
    display: none;
    /* overflow-y: auto; */
    max-width: none;
    position: relative;
    height: 300px;
    justify-content: space-between;
    align-items: center;
}
#logContainer.mobile {
    display: none;
    overflow-y: auto;
    overflow-x: hidden;
    max-width: none;
    position: relative;
    justify-content: end;
    align-items: center;
    margin-bottom: 50px;
}
#logContainer.active {
    display: block;
}

.message.notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.promptCard {
    border-radius: 12px;
    background-color: #303850;
    border-color: #3181b6;
    padding: 8px 16px;
    margin-bottom: 1rem;
}
.promptInfo {
    font-weight: lighter;
    text-align: left;
}
.promptExample {
    cursor: pointer;
    font-weight: medium;
    display: flex;
    align-items: center;
    margin: 0 0.4rem;
    border-style: solid;
    border-width: 1px;
    border-color: #3181b6;
    border-radius: 10px;
    padding: 8px 16px;
}
.promptExample:hover {
    border-color: #55a8e0;
}
.fade-in {
    animation: fadeIn 0.8s ease-in;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-in-fast {
    animation: fadeIn 0.4s ease-in;
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fast-hide {
    display: none;
}

#chatLog {
    scroll-behavior: smooth;
    max-width: none;
    height: 98%;
    width: 100%;
    margin-bottom: 0.8em;
    overflow-y: scroll;
}

.message {
    min-height: 3em !important;
    margin-bottom: 8px;
}
.message > div {
    color: white !important;
}

.message-token-warning  {
    min-height: 3em !important;
    margin-bottom: 8px;
    border: 2px solid #F57F17 !important;
    background-color: rgba(245,160,23, 0.3) !important;
}

.slide-up-move,
.slide-up-enter-active {
    transition: all 0.3s ease-out;
}
.slide-up-enter {
    transform: translateY(100%);
    opacity: 0;
}

.sidebar {
    opacity: 1;
    transition: all 0.3s ease-in !important;
    -webkit-transition: all 0.3s ease-in !important;
}

/* * {
    outline: 0.2px solid aliceblue;
} */

.mobileOptions.v-btn--icon.v-size--default {
    height: 28px;
    width: 32px;
}

html {
    /* scrollbar-gutter: stable; */
    overflow-y: scroll;
    overflow-x: hidden;
    background: #201f1f;
}

.loading {
    font-size: 15px;
    color: white;
}

.loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: 1.25em;
    }
}

#sendBtn:not(.clickable) {
    cursor: auto !important;
}

.sender-icon {
    background-color: transparent !important;
    color: white !important;
    border-radius: 5px !important;
    border-width: 1px;
    border-color: #656565;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

#copiedMessage {
    display: none;
    position: fixed !important;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}
#copiedMessage.show {
    display: unset;
}

.tu-td-double {
    position: absolute;
    right: -44px;
    top: -5px;
    z-index: 5;
    padding-right: 0px;
}

.tu-td-single {
    position: absolute;
    right: -44px;
    top: 8px;
    z-index: 5;
    padding-right: 0px;
}

.warning-border {
  border: 2px solid #F57F17;
  border-radius: 15%;
  padding: 8px; /* Adjust the padding value as needed */
}
.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: transparent !important;
    color: transparent !important;
}
.v-btn:before {
    background-color: transparent !important;
}
.v-btn--is-elevated {
    box-shadow: none !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.maintenance-alert {
    background: rgba(31, 31, 31, 0.9) !important;
}
</style>
