import { Analytics } from 'aws-amplify';

export function landingPageVisit() {
    try {
        Analytics.record({
            name: 'landing_page_visit',
            immediate: true,
            attributes: {
                page_visit: 'landing_page',
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                timestamp_utc: Math.floor(
                    new Date().getTime() / 1000
                ).toString()
            }
        });
    } catch (error) {
        //do nothing
    }
}

export function sendEvent(
    event_type: string,
    userId?: string,
    name?: string,
    email?: string,
    customAttributes?: Object,
) {
    const eventAttributes: any = {
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        timestamp_utc: Math.floor(
            new Date().getTime() / 1000
        ).toString()
    }

    if (userId != undefined) {
        eventAttributes["user_id"] = userId;
    }

    if (email != undefined) {
        eventAttributes["email"] = email;
    }

    if (name != undefined) {
        eventAttributes["name"] = name;
    }

    function isValidAttributeType(value: any) {
        return typeof value !== 'object' && !Array.isArray(value) && value !== null && (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean');
    }
    type CustomAttributes = {
        [key: string]: string | number | boolean;
    };
    
    if (customAttributes !== undefined) {
        for (const key in customAttributes as CustomAttributes) {
            const value = (customAttributes as CustomAttributes)[key]
            if (isValidAttributeType(value)) {
                eventAttributes[key] = value
            }
        }
    }

    try {
        Analytics.record({
            name: event_type,
            immediate: true,
            attributes: eventAttributes
        });
    } catch (error) {
        // do nothing
    }
}
