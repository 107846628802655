
<template>
    <v-app style="background: #201f1f; color: white; overflow: hidden">
        <RFPSideBar
            class="sidebar"
            :user_info="user_info"
            @resetRFPTool="resetRFPTool"
            @updateCollapseValue="sideBarClosed = $event"
            style="z-index: 9"
        />
        <v-main style="background-color: #1e1e1e; border-radius: 10px">
            <v-container id="pageContainer">
                <span style="display: flex; width: 100%; justify-content: right; margin-right:30px; font-weight: 700; font-size: 18px; height: 32px; margin-bottom: 24px;">
                    <header
                        style="
                            margin-bottom: 12px;
                            font-size: 24px;
                            margin-right: auto;
                            position: relative;
                            top: -12px;
                            transition: all 0.3s ease-out !important;
                            -webkit-transition: all 0.3s ease-out !important;
                            -o-transition: all 0.3s ease-out !important;
                            -moz-transition: all 0.3s ease-out !important;
                        "
                        :style="{
                            marginLeft: sideBarClosed ? '64px' : '12px',
                        }"
                    >Bulk Pricing</header>
                    <span class="inactive-rfp-btn" @click="$router.push({name: 'chat'})">Chat</span>
                    <span class="active-rfp-btn">Bulk Pricing</span>
                </span>
                <section style="display: flex; height: calc(100% - 96px); flex-grow: 2; width: 100%">
                    <div class="custom-stepper">
                        <div
                            class="custom-stepper-item"
                            :class="currentState == 'upload' ? 'stepper-selected': '' + (visitable.includes('upload') ? ' stepper-valid' : '')"
                            @click="returnToUpload()"
                        >
                            <div class="stepper-circle-wrap">
                                <div
                                    :key="stepperCircleKeys.upload"
                                    :style="getStepperCircleStyle('upload')"
                                />
                            </div>
                            Upload lanes
                            <span class="stepper-info-text">Upload a .xlsx or .csv of lanes to price</span>
                        </div>
                        <hr class="vertical-line" :style="checklist.has('upload') ? {
                                'border-color': '#7CCCFD',
                                'background-color': '#7CCCFD',
                            } : {}">
                        <div
                            class="custom-stepper-item"
                            :class="currentState == 'validate' ? 'stepper-selected': '' + (visitable.includes('validate') ? ' stepper-valid' : '')"
                            :disabled="currentState == 'validate' || !checklist.has('validate')"
                            @click="changeCurrentState('validate')"
                        >
                            <div class="stepper-circle-wrap">
                                <div
                                    :key="stepperCircleKeys.validate"
                                    :style="getStepperCircleStyle('validate')"
                                />
                            </div>
                            Map Columns
                            <span class="stepper-info-text">Match your spreadsheet columns to our columns</span>
                        </div>
                        <hr class="vertical-line" :style="checklist.has('validate') ? {
                                'border-color': '#7CCCFD',
                                'background-color': '#7CCCFD',
                            } : {}">
                        <div
                            class="custom-stepper-item"
                            :class="currentState == 'equipment' ? 'stepper-selected': '' + (visitable.includes('equipment') ? ' stepper-valid' : '')"
                            :disabled="currentState == 'equipment' || !checklist.has('equipment')"
                            @click="changeCurrentState('equipment')"
                        >
                            <div class="stepper-circle-wrap">
                                <div
                                    :key="stepperCircleKeys.validate"
                                    :style="getStepperCircleStyle('equipment')"
                                />
                            </div>
                            Map Equipment
                            <span class="stepper-info-text">Match your equipment types to corresponding values</span>
                        </div>
                        <hr class="vertical-line" :style="checklist.has('equipment') ? {
                                'border-color': '#7CCCFD',
                                'background-color': '#7CCCFD',
                            } : {}">
                        <div
                            class="custom-stepper-item"
                            :class="currentState == 'price' ? 'stepper-selected': '' + (visitable.includes('price') ? ' stepper-valid' : '')"
                            :disabled="currentState == 'price' || !checklist.has('price')"
                            @click="changeCurrentState('price')"
                        >
                            <div class="stepper-circle-wrap">
                                <div
                                    :key="stepperCircleKeys.price"
                                    :style="getStepperCircleStyle('price')"
                                />
                            </div>
                            Price Lanes
                            <span class="stepper-info-text">Configure pricing options</span>
                        </div>
                        <hr class="vertical-line" :style="checklist.has('price') ? {
                                'border-color': '#7CCCFD',
                                'background-color': '#7CCCFD',
                            } : {}">
                        <div
                            class="custom-stepper-item"
                            :class="currentState == 'complete' ? 'stepper-selected': '' + (visitable.includes('complete') ? ' stepper-valid' : '')"
                            :disabled="currentState == 'complete' || !checklist.has('complete')"
                            @click="changeCurrentState('complete')"
                        >
                            <div class="stepper-circle-wrap">
                                <div
                                    :key="stepperCircleKeys.upload"
                                    :style="getStepperCircleStyle('complete')"
                                />
                            </div>
                            Export Lanes
                            <span class="stepper-info-text">Download priced lanes</span>
                        </div>
                    </div>
                    <div v-show="!isLoading"
                        style="
                            display: flex;
                            flex-direction: column;
                            flex-grow: 2;
                            height: 100%;
                            width: max(75%, 100% - 300px);
                            justify-content: center;
                            align-items: center;
                            overflow-y: auto;
                        "
                        v-if="!resetFlag"
                    >
                        <UploadedFileBar
                            v-if="currentState !== 'upload'"
                            :file_name="uploadedFileName"
                            @changeCurrentState="changeCurrentState"
                        />
                        <UploadStep
                            v-if="currentState === 'upload'"
                            :websocket="lang_chain_socket"
                            :user_id="user_id"
                            :saved_file_name="uploadedFileName"
                            :s3_utils="s3Utils"
                            :rfp_session_id="rfpSessionId"
                            @loading="handleLoadingEvent"
                            @validationResponse="handleValidationResponse"
                            @displayError="showErrorMessage"
                            @updateUploadedFilePath="uploadedFilePath = $event"
                            @updateUploadedFileName="uploadedFileName = $event"
                            @sendAnalyticsEvent="sendAnalyticsEvent($event)"
                        ></UploadStep>
                        <ValidationStep
                            style="height: 100%"
                            v-else-if="currentState === 'validate'"
                            @loading="handleLoadingEvent"
                            @changeCurrentState="changeCurrentState"
                            @proceedToEquipment="loadEquipmentStep"
                            @displayError="showErrorMessage"
                            @updateMappedFilePath="mappedFilePath = $event"
                            @updateColumnMapping="savedColumnMapping = $event"
                            @sendAnalyticsEvent="sendAnalyticsEvent($event)"
                            @updateRemainderColumns="remainderColumns = $event"
                            :websocket="lang_chain_socket"
                            :user_id="user_id"
                            :rfp_session_id="rfpSessionId"
                            :uploaded_file_path="uploadedFilePath"
                            :saved_mapping_data="savedColumnMapping"
                        ></ValidationStep>
                        <EquipmentStep
                            style="height: 100%"
                            v-else-if="currentState === 'equipment'"
                            @loading="handleLoadingEvent"
                            @changeCurrentState="changeCurrentState"
                            @proceedToPrice="loadPricingStep"
                            @displayError="showErrorMessage"
                            @sendAnalyticsEvent="sendAnalyticsEvent($event)"
                            @updateEquipFilePath="equipFilePath = $event"
                            @updateEquipmentMapping="savedEquipmentMapping = $event"
                            :source_columns="remainderColumns"
                            :websocket="lang_chain_socket"
                            :user_id="user_id"
                            :rfp_session_id="rfpSessionId"
                            :uploaded_file_path="uploadedFilePath"
                            :saved_mapping_data="savedEquipmentMapping"
                        ></EquipmentStep>
                        <PricingStep
                            style="height: 100%"
                            v-else-if="currentState === 'price'"
                            @loading="handleLoadingEvent"
                            @changeCurrentState="changeCurrentState"
                            @proceedToResult="loadCompleteStep"
                            @displayError="showErrorMessage"
                            @pricingResponse="handlePricingResponse"
                            @updateSavedOptions="savedOptions = $event"
                            @sendAnalyticsEvent="sendAnalyticsEvent($event)"
                            :websocket="lang_chain_socket"
                            :user_id="user_id"
                            :rfp_session_id="rfpSessionId"
                            :mapped_file_path="mappedFilePath"
                            :saved_pricing_options="savedOptions"
                        ></PricingStep>
                        <ResultStep
                            :s3_utils="s3Utils"
                            :user_info="user_info"
                            style="height: 100%"
                            v-else-if="currentState === 'complete'"
                            @loading="handleLoadingEvent"
                            @changeCurrentState="changeCurrentState"
                            :original_file_name="uploadedFileName"
                            :pricing_response_data="pricingResponseData"
                        ></ResultStep>
                    </div>
                </section>
                <v-alert
                    type="error"
                    absolute
                    dense
                    dismissible
                    style="
                        font-size: 1.2rem;
                        margin: 0 6px;
                        position: absolute;
                        bottom: 10%;
                        left: 50%;
                    "
                    v-model="displayError"
                >
                    {{ errorMessage }}
                </v-alert>
            </v-container>

            <v-overlay absolute :value="isLoading">
                <RFPLoader style="z-index: 220"
                    :loading_message="loadingMessage"
                />
            </v-overlay>
        </v-main>
    </v-app>
</template>

<script>
import PricingStep from '../components/rfp_tool/PricingStep.vue'
import ResultStep from '../components/rfp_tool/ResultStep.vue'
import UploadStep from '../components/rfp_tool/UploadStep.vue'
import ValidationStep from '../components/rfp_tool/ValidationStep.vue'
import EquipmentStep from '../components/rfp_tool/EquipmentStep.vue'
import RFPSideBar from '../components/rfp_tool/RFPSideBar.vue'
import UploadedFileBar from '../components/rfp_tool/UploadedFileBar.vue'
import RFPLoader from '@/components/RFPLoader.vue'
import LangChainSocket from '../websocket';
import { v4 as uuidv4 } from 'uuid';
import * as analytics from '../analytics/pinpoint';
import { Auth } from 'aws-amplify';
import { storeTokenWithRefresh, clearRefreshTimer } from '../utils/aws-cognito-auth';
import S3Utils from '../utils/s3-utils';
import { isDevEnv, APIEndpoints } from '../resources';
import '../components/rfp_tool/rfp-shared-styles.css';
import _ from 'lodash';
const BUCKET_PATH = `s3://${process.env.VUE_APP_RFP_BUCKET_NAME}/`;

export default {
    name: 'RFPTool',
    components: {
        RFPSideBar,
        PricingStep,
        ResultStep,
        UploadStep,
        ValidationStep,
        EquipmentStep,
        UploadedFileBar,
        RFPLoader,
    },
    data() {
        return {
            lang_chain_socket: null,
            rfpSessionId: "",
            s3Utils: new S3Utils(),
            currentState: 'upload', // upload, validate, equipment, price, complete
            resetFlag: false,
            checklist: new Set(),
            visitable: ['upload'],
            isLoading: false,
            loadingMessage: 'Loading',
            componentUid: uuidv4(),
            displayError: false,
            errorMessage: '',
            user_info: {},
            user_id: '',
            savedColumnMapping: {},
            mappedFilePath: '',
            savedEquipmentMapping: {},
            equipFilePath: '',
            savedOptions: null,
            stepperCircleKeys: {
                upload: 0,
                validate: 0,
                price: 0,
                complete: 0,
            },
            sideBarClosed: false,
            uploadedFilePath: '',
            uploadedFileName: null,
            remainderColumns: [],
        };
    },
    watch: {
        currentState(val) {
            this.sendAnalyticsEvent(`${val}_view`)
            if (val !== 'upload') {
                this.displayError = false;
            }
        },
    },
    methods: {
        resetRFPTool() {
            this.resetFlag = true;
            this.$nextTick(() => {
                this.resetFlag = false;
                this.currentState = 'upload';
                this.checklist = new Set();
                this.visitable = ['upload'];
                this.isLoading = false;
                this.displayError = false;
                this.errorMessage = '';
                this.savedColumnMapping = {};
                this.savedEquipmentMapping = {};
                this.savedOptions = null;
                this.uploadedFilePath = '';
                this.uploadedFileName = null;
            });
        },
        changeCurrentState(val){
            this.currentState = val
        },
        handleLoadingEvent(loadingMessage) {
            if (loadingMessage) {
                this.isLoading = true;
                if (typeof loadingMessage === 'string') {
                    this.loadingMessage = loadingMessage;
                } else {
                    this.loadingMessage = 'Loading';
                }
            } else {
                this.isLoading = false;
            }
        },
        loadEquipmentStep() {
            if (Object.keys(this.savedEquipmentMapping).length === 0) {
                this.lang_chain_socket.sendMessage({
                    action: isDevEnv() ? APIEndpoints.dev.rfp_equipment_endpoint : APIEndpoints.prod.rfp_equipment_endpoint,
                    user_id: this.user_id,
                    rfp_session_id: this.rfpSessionId,
                    sourceFilename: BUCKET_PATH + this.uploadedFilePath
                }, (response) => {
                    let responseData = JSON.parse(response.data);
                    if ('fatalError' in responseData) {
                        this.showErrorMessage(responseData.fatalError)
                        this.isLoading = false;
                    } else if ('equipmentColumnMapping' in responseData) {
                        this.savedEquipmentMapping = responseData
                        this.isLoading = false;
                        this.checklist.add('validate');
                        this.visitable.push('equipment');
                        this.currentState = 'equipment';
                    }
                })
            } else {
                this.isLoading = false;
                this.currentState = 'equipment';
            }
        },
        loadPricingStep() {
            this.checklist.add('equipment');
            this.visitable.push('price');
            this.isLoading = false;
            this.currentState = 'price';
        },
        loadCompleteStep() {
            this.checklist.add('price');
            this.visitable.push('complete');
            this.isLoading = false;
            this.currentState = 'complete';
        },
        returnToUpload() {
            this.currentState = 'upload';
        },
        handleValidationResponse(responseData) {
            // This callback differs from that in the ValidationStep component as it handles the automatic validation that occurs when a file is uploaded
            // Notably it is also responsible for reinitializing saved data and handling the state transition to the validation step
            this.mappedFilePath = responseData.mappedFilename;
            this.savedColumnMapping = _.cloneDeep(responseData);
            this.savedOptions = null;
            this.checklist = new Set(['upload']);
            this.visitable = ['upload', 'validate'];
            this.currentState = 'validate';
        },
        handlePricingResponse(responseData) {
            this.pricingResponseData = responseData
        },
        getStepperCircleStyle(stepName) {
            const defaultClause = {
                'margin-right': '6px',
                'border-radius': '50%',
            }
            const selectClause = this.currentState === stepName ? {
                'height': '20px',
                'width': '20px',
                'background-color': '#7CCCFD',
            } : {
                'height': '10px',
                'width': '10px',
                'background-color': 'rgb(179, 179, 179)',
            }
            const visitedClause = this.visitable.includes(stepName) ? {
                'background-color': '#7CCCFD',
            } : {
                'border': '2px solid rgb(179, 179, 179)',
            }
            return {
                ...defaultClause,
                ...selectClause,
                ...visitedClause,
            };
        },
        showErrorMessage(message) {
            this.displayError = true;
            this.isLoading = false;
            this.errorMessage = message;
        },
        sendAnalyticsEvent(eventName) {
            analytics.sendEvent(`rfp_${eventName}`, this.user_info.sub, this.user_info.name, this.user_info.email, { rfpSessionId: this.rfpSessionId });
        },
        authenticateUser() {
            Auth.currentAuthenticatedUser().then((user) => {
                this.user_id = user.attributes.sub;
                this.user_info = user.attributes;
                this.sendAnalyticsEvent('tool_view')
                this.retrieveSessionAndAuthenticate()
            });
        },
        retrieveSessionAndAuthenticate(attempts=4) {
            this.lang_chain_socket.sendMessage({
                action: isDevEnv() ? APIEndpoints.dev.rfp_new_session_endpoint : APIEndpoints.prod.rfp_new_session_endpoint,
                user_id: this.user_id
            }, (response)=>{
                let responseData = JSON.parse(response.data);
                if ('rfp_session_id' in responseData) {
                    this.rfpSessionId = responseData.rfp_session_id
                    storeTokenWithRefresh(responseData.rfp_session_id.idToken)
                } else if ('fatalError' in responseData) {
                    if (attempts > 0) {
                        setTimeout(() => {
                            this.retrieveSessionAndAuthenticate(attempts-1)
                        }, 4000)
                    }
                }
            })
        }
    },
    beforeMount() {
        this.lang_chain_socket = new LangChainSocket();
        this.authenticateUser();
    },
    beforeDestroy() {
        clearRefreshTimer()
    },
};
</script>
<style scoped>
::v-deep .v-main__wrap {
    display: flex;
    justify-content: center;
}
#pageContainer {
    border-radius: 20px;
    background: #23252a;
    top: 3%;
    height: 94vh;
    max-height: 94vh;
    z-index: 4;
    position: relative;
    width: calc(100% - 3rem);
    max-width: calc(100% - 3rem);
    margin-right: 0.6rem;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
}

.custom-stepper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: 90%;
    font-weight: 360;
    font-size: 16px;
    width: min(25%, 300px);
    margin-right: 12px;
}
.vertical-line {
    width: 2px !important;
    z-index: 2;
    margin: -10px 8px;
    height: calc(100% + 20px);
    background-color: rgb(179, 179, 179);
    border-color: rgb(179, 179, 179);
    border-left-style: outset;
}
.custom-stepper-item {
    pointer-events: none;
    width: 100%;
    user-select: none;
    color: rgba(255, 255, 255, 0.9);
    display: flex;
    position: relative;
}
.stepper-selected {
    cursor: default;
}
.stepper-valid {
    cursor: pointer;
    pointer-events: auto;
}
.stepper-info-text {
    width: 90%;
    font-size: 14px;
    top: 22px;
    left: 24px;
    position: absolute;
    color: rgba(255, 255, 255, 0.5) !important;
}
.stepper-circle-wrap {
    display: flex;
    z-index: 5;
    width: 24px;
    height: 100%;
    justify-content: center;
    align-items: center
}
</style>
