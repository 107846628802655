<template>
    <div
        class="px-4 d-flex"
        style="
            margin-left: 4%;
            margin-bottom: 6%
        "
    >
        <v-menu offset-y top nudge-top="8">
            <template v-slot:activator="{ on, attrs }">
                <div
                    style="width: 90%; border-radius: 0.2rem"
                    :ripple="false"
                    class="pl-1 pr-2 d-flex flex-nowrap hover-effect"
                    v-bind="attrs"
                    :input-value="false"
                    role="button"
                    v-on="on"
                >
                    <v-avatar
                        color="transparent"
                        size="28"
                        style="
                            flex: 0 0 auto !important;
                            color: white;
                            align-self: center;
                        "
                    >
                        <v-icon color="white"
                            >mdi-account-outline</v-icon
                        >
                    </v-avatar>
                    <span
                        style="
                            flex-shrink: 1;
                            color: #ffffff;
                            font-size: 18px;
                            padding-top: 14px;
                            padding-bottom: 12px;
                            padding-left: 20px;
                        "
                    >
                        {{ user_info.name }}
                    </span>
                </div>
            </template>
            <v-list
                style="
                    border-color: rgb(196, 196, 196);
                    border-style: solid;
                    border-radius: 0.2rem;
                    border-width: 0.1rem !important;
                    background: #252527;
                "
            >
                <v-list-item style="color: #ffffff" @click="signOut"
                    >Sign Out</v-list-item
                >
            </v-list>
        </v-menu>
        <v-fab-transition>
            <v-btn
                color="#3181b6"
                dark
                style="z-index: 100; align-self: center"
                icon
                @click="helpMenu = !helpMenu"
            >
                <v-icon>mdi-help-circle-outline</v-icon>
            </v-btn>
        </v-fab-transition>

        <!-- Help Dialog -->
        <v-dialog
            v-model="helpMenu"
            top
            :close-on-content-click="false"
            :close-on-click="false"
            :width="is_mobile ? '100%' : '30%'"
            style="z-index: 101"
        >
            <v-form ref="contactForm" v-model="contactFormValid">
                <v-card
                    class="pa-3"
                    style="background-color: #201f1f; color: white"
                >
                    <v-card-title
                        class="pt-3 pb-3"
                        style="border-bottom: 1px solid #656565"
                    >
                        <span
                            class="text-capitalize text-subtitle-2 text-sm-h6 text-md-h6 text-lg-h6 text-xl-h6"
                        >
                            How can we help?
                        </span>
                    </v-card-title>
                    <v-card-text class="pt-8 pb-5">
                        <v-row>
                            <v-col cols="12">
                                <v-textarea
                                    name="input-7-1"
                                    dark
                                    filled
                                    hide-details
                                    solo
                                    flat
                                    auto-grow
                                    no-resize
                                    v-model="comment"
                                    background-color="rgba(48,56,80,0.5)"
                                    counter
                                    :maxlength="commentMaxLength"
                                    light
                                ></v-textarea>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            class="feedback-button mr-2"
                            style="border-color: rgb(255 0 0)"
                            color="#F8F8F8"
                            outlined
                            @click="
                                helpMenu = false;
                                resetForm();
                            "
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            light
                            color="#F8F8F8"
                            outlined
                            class="feedback-button mr-2"
                            @click="sendFeedbackEmail()"
                        >
                            Send
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            top
            style="position: fixed; top: 6px; z-index: 30; left: 48vw"
            class="override-snackbar-wrapper"
            >
            {{ snackText }}
        </v-snackbar>
    </div>
</template>

<script>
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { sendContactMessage } from '../contact';
import { useIdle } from '@vueuse/core';
import * as analytics from '../analytics/pinpoint';

export default {
    name: 'SideBarFooter',
    props: {
        user_info: {
            type: Object,
            required: true,
        },
        is_mobile: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            helpMenu: false,
            contactFormValid: false,
            commentMaxLength: 500,
            comment: '',
            idle: useIdle(10 * 60 * 60 * 1000),
            snackbar: false,
            snackText: '',
            snackColor: 'green',
        };
    },
    watch: {
        'idle.idle': async function (newValue, oldValue) {
            if (newValue && !oldValue) {
                this.signoutUser();
            }
        },
    },
    methods: {
        resetForm() {
            const contactForm = this.$refs.contactForm;
            contactForm.reset();
        },

        validateForm() {
            const contactForm = this.$refs.contactForm;
            return contactForm.validate();
        },

        sendFeedbackEmail() {
            // only send email if form validation passes
            if (this.validateForm()) {
                const body = {
                    name: '[FreightGPT][Help] ' + this.user_info?.name,
                    company: '',
                    email: this.user_info?.email,
                    comment: this.sanitizeString(this.comment)
                };

                sendContactMessage(body);

                this.helpMenu = false;

                this.resetForm();

                this.snackText = 'Message successfully sent.';
                this.snackColor = 'green';
                this.snackbar = true;
            } else {
                this.snackText = 'Invalid message.';
                this.snackColor = 'red';
                this.snackbar = true;
            }
        },

        sanitizeString(text) {
            let pattern = /[<>{}()"'`;/\\]/g;
            if (text) {
                return text.replace(pattern, '');
            } else {
                return text;
            }
        },

        async signOut() {
            try {
                await Auth.signOut({ global: true });
                analytics.sendEvent(
                    'logout',
                    this.user_info.sub,
                    this.user_info.name,
                    this.user_info.email
                );
                AWS.config.credentials = null;
                this.$router.push({
                    name: 'landing'
                });
            } catch (error) {
                if (error.code === 'NotAuthorizedException' && error.message.includes('Access Token has been revoked')) {
                    analytics.sendEvent(
                        'logout',
                        this.user_info.sub,
                        this.user_info.name,
                        this.user_info.email
                    );
                    AWS.config.credentials = null;
                    this.$router.push({
                        name: 'landing'
                    });
                } else {
                    console.error('error signing out');
                }
            }
        },

        async signoutUser() {
            const currentUser = await Auth.currentAuthenticatedUser({
                bypassCache: true
            });
            if (currentUser) {
                console.log('Signing Out');
                try {
                    await Auth.signOut({ global: true });
                    analytics.sendEvent(
                        'logout',
                        currentUser.attributes.sub,
                        currentUser.attributes.name,
                        currentUser.attributes.email
                    );
                    AWS.config.credentials = null;
                    this.$router.push({
                        name: 'landing'
                    });
                } catch (error) {
                    if (error.code === 'NotAuthorizedException' && error.message.includes('Access Token has been revoked')) {
                        analytics.sendEvent(
                            'logout',
                            currentUser.sub,
                            currentUser.name,
                            currentUser.email
                        );
                        AWS.config.credentials = null;
                        this.$router.push({
                            name: 'landing'
                        });
                    } else {
                        console.error('error signing out');
                    }
                }
            }
        },
    },
}
</script>

<style scoped>
.feedback-button {
    text-transform: capitalize; /* Lowercase text */
    border-color: rgb(49, 129, 183);
    font-weight: 400;
}
.hover-effect:hover {
    background-color: rgba(222, 222, 222, 0.2) !important;
}
</style>
