export const WEBSOCKET_LINK =
    'wss://wps3vtux75.execute-api.us-east-1.amazonaws.com/beta-1';
export const LOCAL_TESTING_API = `wss://4mp7e4uxth.execute-api.us-east-1.amazonaws.com/${process.env.VUE_APP_STAGE_NAME}/`;
export const PROD_URL = 'freightgpt.com';
export const DEV_URL = 'localhost';
export const TEST_URL = 'amplifyapp.com'; // Dev url dev.freightgpt.com redirects to https://dev.d2ankb8sikpwif.amplifyapp.com/
export const CONVERSATION_API =
    'https://j38xgzxny6.execute-api.us-east-1.amazonaws.com/beta-1/';
export const PROD_CONVERSATIONS = CONVERSATION_API + 'getConversation';
export const DEV_CONVERSATIONS = CONVERSATION_API + 'getConversationDev';
export const PROD_OAUTH_COGNITO_DOMAIN = 'auth.freightgpt.com';
export const DEV_OAUTH_COGNITO_DOMAIN = 'freightgpt-dev.auth.us-east-1.amazoncognito.com';

export function isDevEnv() {
    return !window.location.href.includes(PROD_URL);
}

export const APIEndpoints = {
    prod: {
        new_session_endpoint: 'newSession',
        chat_endpoint: 'postMessage',
        hit_count_endpoint: 'getHitCount',
        carrier_search_endpoint: 'runCarrierSearch',
        feedback_endpoint: 'feedback',
        rfp_new_session_endpoint: 'newRfpSession',
        rfp_validation_endpoint: 'mapValidateSourceFile',
        rfp_equipment_endpoint: 'mapValidateEquipment',
        rfp_pricing_endpoint: 'priceValidatedFile',
        maintenance_alert:'https://gdlba8aco7.execute-api.us-east-1.amazonaws.com/beta-1'
    },
    dev: {
        new_session_endpoint: 'newSessionDev',
        chat_endpoint: 'postMessageDev',
        hit_count_endpoint: 'getHitCountDev',
        carrier_search_endpoint: 'runCarrierSearchDev',
        feedback_endpoint: 'feedbackDev',
        rfp_new_session_endpoint: 'newRfpSessionDev',
        rfp_validation_endpoint: 'mapValidateSourceFileDev',
        rfp_equipment_endpoint: 'mapValidateEquipmentDev',
        rfp_pricing_endpoint: 'priceValidatedFileDev',
        maintenance_alert:'https://j555fupckb.execute-api.us-east-1.amazonaws.com/beta-1'
    }
};
